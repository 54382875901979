/* eslint-disable no-nested-ternary */
import React, { useState } from "react";

import {
  faFileSignature,
  faInfoCircle,
  faUserFriends,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { decode } from "html-entities";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import {
  openControlDocument,
  openDocument,
  openDocumentPreview,
} from "../../api/auth";
import { useGetDocumentByIdQuery } from "../../api/documents.generated";
import { getDocumentSignatureStatus } from "../../api/help";
import DocumentStatusBadge from "../../components/badges/DocumentStatusBadge";
import BackButton from "../../components/buttons/BackButton";
import CheckButton from "../../components/buttons/CheckButton";
import DownloadButton from "../../components/buttons/DownloadButton";
import EditButton from "../../components/buttons/EditButton";
import PreviewButton from "../../components/buttons/PreviewButton";
import RefreshButton from "../../components/buttons/RefreshButton";
import SignButton from "../../components/buttons/SignButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import DeleteDocumentModal from "../../components/modals/DeleteDocumentModal";
import SignDocumentModal from "../../components/modals/SignDocumentModal";
import PageHeader from "../../components/page/PageHeader";
import { CustomerTypeKeys } from "../../enums/CustomerType";
import {
  CHECK_DELETED,
  DELETED,
  DRAFT,
  SIGNED,
  SIGNING_IN_PROGRESS,
} from "../../enums/DocumentStatusKey";
import { DocumentTypeKeys } from "../../enums/DocumentTypes";
import PermissionOperationTypeKey from "../../enums/PermissionOperationType";
import Roles from "../../enums/Roles";
import SignatureType from "../../enums/SignatureType";
import ZUZDocumentSection from "../../enums/ZUZDocumentSection";
import useDocumentGetters from "../../hooks/documentContent";
import { useZUZRights } from "../../hooks/documentPermissions";
import useDocumentValidation from "../../hooks/documentValidation";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import { useAppSelector } from "../../hooks/store";
import { DocumentContent } from "../../models/DocumentContent";
import { DocumentMode } from "../../models/DocumentMode";
import { authSelector } from "../auth/authSlice";
import Error403View from "../error/Error403View";
import Error404View from "../error/Error404View";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";

function DocumentZuzView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { formatTimeWithoutSeconds } = useFormatters();
  const navigate = useNavigate();
  const { roleId, currentRole } = useAppSelector(authSelector);
  const {
    validate: runValidation,
    validateDocumentCustomers: runCustomersValidation,
  } = useDocumentValidation();
  const canUpdate = useZUZRights(PermissionOperationTypeKey.UPDATE);

  const { currentData, isFetching, isError, error, refetch } =
    useGetDocumentByIdQuery(
      {
        "X-Role-Id": getRoleId(),
        id: id ?? "0",
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  if (currentData && currentData.type?.key === DocumentTypeKeys.CHECK_FORM) {
    const path = makeRoute(AllRoutes.DOCUMENT_VIEW_CHECK, {
      id,
    });
    navigate(path);
  }

  const { getRequestData } = useDocumentGetters();

  const { validate: runValidaton } = useDocumentValidation();

  // Preview logic
  const [isPreviewInProgress, setIsPreviewInProgress] = useState(false);
  const [isPreviewDisabled, setIsPreviewDisabled] = useState(false);
  const previewPdf = () => {
    setIsPreviewInProgress(true);
    setIsPreviewDisabled(true);
    openDocumentPreview(id ?? "", roleId)
      .catch(() => {
        toast.error(t("toast.document.error.pdfPreview"));
      })
      .finally(() => {
        setIsPreviewInProgress(false);
        setIsPreviewDisabled(false);
      });
  };

  // Download logic
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(false);
  const downloadPdf = () => {
    setIsDownloadInProgress(true);
    setIsDownloadDisabled(true);
    openDocument(id ?? "")
      .catch(() => {
        toast.error(t("toast.document.error.pdfPreview"));
      })
      .finally(() => {
        setIsDownloadInProgress(false);
        setIsDownloadDisabled(false);
      });
  };

  // Download logic
  const [isDownloadControlPDFInProgress, setIsDownloadControlPDFInProgress] =
    useState(false);
  const [isDownloadControlPDFDisabled, setIsDownloadControlPDFDisabled] =
    useState(false);
  const downloadControlPdf = () => {
    setIsDownloadControlPDFInProgress(true);
    setIsDownloadControlPDFDisabled(true);
    openControlDocument(id ?? "")
      .catch(() => {
        toast.error(t("toast.document.error.pdfPreview"));
      })
      .finally(() => {
        setIsDownloadControlPDFInProgress(false);
        setIsDownloadControlPDFDisabled(false);
      });
  };

  const [showSignModal, setShowSignModal] = useState(false);
  const handleSignButtonClicked = () => {
    // setShowSignModal(!showSignModal);
    const fields = runValidation(
      (currentData?.content || {}) as DocumentContent,
      currentData?.content?.disabledSections
    );

    const customersValidation = runCustomersValidation(currentData?.customers);

    if (fields.length > 0) {
      let message = t("toast.document.error.validation");
      const idValidUntilMessage = t("document.validation.idValidUntil.expired");

      const clientIdValidUntilError = fields.find(
        (f) => f.field === "clientIdValidUntil"
      );

      const parttnerIdValidUntilError = fields.find(
        (f) => f.field === "partnerIdValidUntil"
      );

      if (
        !clientIdValidUntilError?.valid &&
        clientIdValidUntilError?.message === idValidUntilMessage
      ) {
        message = t("document.validation.client.idValidUntil.expired");
      }

      if (
        !parttnerIdValidUntilError?.valid &&
        parttnerIdValidUntilError?.message === idValidUntilMessage
      ) {
        message = t("document.validation.partner.idValidUntil.expired");
      }

      toast.error(message);
      return;
    }

    customersValidation.forEach((message) => {
      toast.error(message);
    });

    if (customersValidation.length > 0) {
      return;
    }

    setShowSignModal(true);
  };

  const refreshDocumentStatus = () => {
    getDocumentSignatureStatus(id ?? "0").then((response) => {
      if (response.state === "completed") {
        toast.success(t("toast.document.success.signed"));
        refetch();
      } else {
        toast.info(t("toast.document.info.stillInProgress"));
      }
    });
  };

  const hasPermissionToDelete = !(
    (currentRole.role?.key === Roles.ADVISOR ||
      currentRole.role?.key === Roles.ASSISTANT) &&
    (currentData?.status?.key === SIGNED ||
      currentData?.status?.key === SIGNING_IN_PROGRESS)
  );

  if (!isFetching && isError && error) {
    if ("data" in error && error.status === 403) {
      return <Error403View error={error} />;
    }

    return <Error404View />;
  }

  const validate = () => {
    if (!currentData) {
      return;
    }
    const requestData = getRequestData(DocumentMode.EDIT, currentData);
    const content = JSON.parse(requestData.content as string);

    const fields = runValidaton(
      content,
      currentData?.content?.disabledSections as ZUZDocumentSection[]
    );

    if (fields.length > 0) {
      let message = t("toast.document.error.validation");
      const idValidUntilMessage = t("document.validation.idValidUntil.expired");

      const clientIdValidUntilError = fields.find(
        (f) => f.field === "clientIdValidUntil"
      );

      const parttnerIdValidUntilError = fields.find(
        (f) => f.field === "partnerIdValidUntil"
      );

      if (
        !clientIdValidUntilError?.valid &&
        clientIdValidUntilError?.message === idValidUntilMessage
      ) {
        message = t("document.validation.client.idValidUntil.expired");
      }

      if (
        !parttnerIdValidUntilError?.valid &&
        parttnerIdValidUntilError?.message === idValidUntilMessage
      ) {
        message = t("document.validation.partner.idValidUntil.expired");
      }

      toast.warn(message);
    } else {
      toast.success(t("toast.document.success.validation"));
    }
  };

  if (isFetching && !currentData) return <PageLoader />;

  return (
    <>
      <PageHeader label={currentData?.displayName || ""} icon={faFileSignature}>
        <BackButton
          onClick={() => {
            navigate(makeRoute(AllRoutes.DOCUMENTS_LIST_ZUZ, { id }));
          }}
        />
        {currentData?.status?.key === DRAFT && currentData?.id && canUpdate ? (
          <EditButton
            onClick={() => {
              navigate(makeRoute(AllRoutes.DOCUMENT_EDIT_ZUZ, { id }));
            }}
          />
        ) : (
          ""
        )}
        {currentData?.status?.key !== SIGNED && currentData?.id ? (
          <PreviewButton
            inProgress={isPreviewInProgress}
            onClick={() => previewPdf()}
            disabled={isPreviewDisabled}
          />
        ) : (
          ""
        )}
        {currentData?.status?.key === SIGNING_IN_PROGRESS &&
        currentData?.id &&
        canUpdate ? (
          <RefreshButton onClick={refreshDocumentStatus} />
        ) : (
          ""
        )}
        {currentData?.status?.key === DRAFT && currentData?.id && canUpdate ? (
          <CheckButton
            onClick={() => validate()}
            dataTestId="/documents/zuz/btn-check"
          />
        ) : (
          ""
        )}
        {currentData?.status?.key === SIGNED && currentData?.id ? (
          <DownloadButton
            inProgress={isDownloadInProgress}
            onClick={() => downloadPdf()}
            disabled={isDownloadDisabled}
          />
        ) : (
          ""
        )}
        {currentData?.status?.key === SIGNED ? (
          <DownloadButton
            inProgress={isDownloadControlPDFInProgress}
            label="button.downloadControl"
            onClick={() => downloadControlPdf()}
            disabled={isDownloadControlPDFDisabled}
          />
        ) : (
          ""
        )}
        {(currentData?.status?.key === DRAFT ||
          (currentData?.status?.key === SIGNING_IN_PROGRESS &&
            currentData?.signatureType?.key === SignatureType.LINK &&
            currentData?.id)) &&
        canUpdate ? (
          <SignButton onClick={() => handleSignButtonClicked()} />
        ) : (
          ""
        )}
        {currentData?.status?.key !== DELETED &&
        currentData?.status?.key !== CHECK_DELETED &&
        hasPermissionToDelete &&
        currentData?.id &&
        canUpdate ? (
          <DeleteDocumentModal
            documentId={currentData?.id}
            onDeleteDocument={() => {
              refetch();
            }}
          />
        ) : (
          ""
        )}
      </PageHeader>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader icon={faInfoCircle} label={t("form.header.info") || ""}>
              <DocumentStatusBadge
                className="ml-2"
                status={currentData?.status}
                small
              />
            </CardHeader>
            <CardBody>
              <div className="grid grid-cols-2 gap-x-4 pb-2">
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("form.label.created")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {formatTimeWithoutSeconds(currentData?.createdAt)}
                  </div>
                </div>
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("form.label.updated")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {formatTimeWithoutSeconds(currentData?.updatedAt)}
                  </div>
                </div>
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("form.label.advisor")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {`${currentData?.advisor?.firstname} ${currentData?.advisor?.lastname} (${currentData?.advisor?.user?.email})`}
                  </div>
                </div>
                <div className="my-2">
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("app.document.modal.sign.title")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {currentData?.content?.signatureType === "" ||
                    !currentData?.content?.signatureType
                      ? typeof currentData?.signatureType?.key !==
                          "undefined" &&
                        currentData?.signatureType?.key?.length > 0
                        ? t(`signature-${currentData?.signatureType?.key}`)
                        : " - "
                      : t(currentData?.content?.signatureType)}
                  </div>
                </div>
                <div>
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("app.document.mentor")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {currentData && currentData.mentor
                      ? `${currentData.mentor.firstname} ${currentData.mentor.lastname} (${currentData.mentor.user?.email})`
                      : " - "}
                  </div>
                </div>
                <div>
                  <div className="block text-gray-500 text-sm truncate">
                    <span>{t("app.document.shareToAdvisor")}</span>
                  </div>
                  <div className="block text-sm text-gray-500 font-bold">
                    {currentData && currentData.sharedAdvisor
                      ? `${currentData.sharedAdvisor.displayName} (${currentData.sharedAdvisor?.email})`
                      : " - "}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              label={t("app.page.customers.heading") || ""}
              icon={faUserFriends}
            />
            <CardBody className="flex flex-col gap-8">
              <div className="grid grid-cols-1 gap-x-4 pb-2">
                {currentData?.customers
                  ? displayCustomers(currentData?.content, t)
                  : ""}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-8">
        <Card>
          <CardHeader
            label={t("app.page.zuzSolutions.heading") || ""}
            icon={faTable}
          />
          <CardBody className="flex flex-col gap-8">
            <table>
              <thead>
                <tr className="cursor-default">
                  <td>
                    <span>{t("table.header.financialInstitution")}</span>
                  </td>
                  <td>
                    <span>{t("table.header.typeAndProductFund")}</span>
                  </td>
                  <td>
                    <span>{t("table.header.bondSum")}</span>
                  </td>
                  <td>
                    <span>{t("table.header.depositFrequency")}</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                {currentData?.content?.pfaRecomendationsTable
                  .filter(
                    (item: string[]) =>
                      item[0] !== "" ||
                      item[1] !== "" ||
                      item[2] !== "" ||
                      item[3] !== ""
                  )
                  .map((i: string, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={`solution-${index}`}>
                      <td>{decode(i[0])}</td>
                      <td>{decode(i[1])}</td>
                      <td>{decode(i[2])}</td>
                      <td>{decode(i[3])}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </CardBody>
        </Card>
      </div>
      <SignDocumentModal
        document={currentData}
        show={showSignModal}
        setShow={setShowSignModal}
        callback={() => {
          refetch();
        }}
      />
    </>
  );
}

function displayCustomers(
  content: { [key: string]: any } | undefined,
  t: TFunction<"translation", undefined>
) {
  return (
    <div>
      <div className="my-2">
        <div className="block text-gray-500 text-sm truncate">
          <span>{t("form.label.customer")}</span>
        </div>
        <div className="block text-sm text-gray-500 font-bold">
          {content?.clientDisplayName ?? content?.clientName}
          {`${
            content?.clientPersonType[0] && content?.clientPersonType[0].checked
              ? ` (${t("form.label.pin")}: ${content?.clientIdNumber}`
              : ` (${t("form.label.ico")}: ${content?.clientBusinessId}`
          })`}
        </div>
      </div>
      {content?.partnerName ? (
        <div className="my-3">
          <div className="block text-gray-500 text-sm truncate">
            <span>{t("form.label.partner")}</span>
          </div>
          <div className="block text-sm text-gray-500 font-bold">
            <span>
              {content.partnerPersonType === CustomerTypeKeys.CT_FO
                ? content?.partnerName
                : content.partnerCompanyName ?? content.partnerName}
              {`${
                content?.partnerPersonType === CustomerTypeKeys.CT_FOP ||
                content?.partnerPersonType === CustomerTypeKeys.CT_PO
                  ? ` (${t("form.label.ico")}: ${
                      content?.partnerBusinessNumber
                    }`
                  : ` (${t("form.label.pin")}: ${content?.partnerIdNumber}`
              })`}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default DocumentZuzView;
