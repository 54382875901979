import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface ApiError {
  message?: string;
  code?: string;
  status?: number;
  data?: any;
}

interface ErrorHandlers {
  on400?: (data: any) => void;
  on401?: () => void;
  on403?: () => void;
  on404?: () => void;
  on500?: () => void;
  onDefault?: () => void;
  onFinally?: () => void;
}

const useHandleApiError = () => {
  const { t } = useTranslation();

  const handleApiError = (error: ApiError, handlers?: ErrorHandlers) => {
    // Handle specific error codes
    if (error.code) {
      const translatedError = t(`errors.${error.code}`);
      if (translatedError !== `errors.${error.code}`) {
        toast.error(translatedError);
        return;
      }
    }

    // Handle HTTP status codes
    if (error.status) {
      switch (error.status) {
        case 400:
          handlers?.on400?.(error.data);
          break;
        case 401:
          handlers?.on401
            ? handlers.on401()
            : toast.error(t("app.page.error.unauthorized"));
          break;
        case 403:
          handlers?.on403
            ? handlers.on403()
            : toast.error(t("app.page.error.forbidden"));
          break;
        case 404:
          handlers?.on404
            ? handlers.on404()
            : toast.error(t("app.page.error.not_found"));
          break;
        case 500:
          handlers?.on500
            ? handlers.on500()
            : toast.error(t("app.page.error.server"));
          break;
        default:
          handlers?.onDefault?.() || toast.error(t("app.page.error.unknown"));
      }
      handlers?.onFinally?.();
      return;
    }

    // Fallback for generic error message
    const errorMessage = error.message || t("errors.unknown");
    toast.error(errorMessage);
    handlers?.onFinally?.();
  };

  return handleApiError;
};

export default useHandleApiError;
