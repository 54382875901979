import React, { FormEvent, useState } from "react";

import {
  IconDefinition,
  faPlus,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import {
  AdvisorProtectedView,
  GetAdvisorsListAllApiArg,
  useGetAdvisorsListAllQuery,
} from "../../api/advisors.generated";
import TableBodyLoading from "../../features/table/TableBodyLoading";
import TablePagination from "../../features/table/TablePagination";
import Alert from "../alerts/Alert";
import AddButton from "../buttons/AddButton";
import Button from "../buttons/Button";
import CancelButton from "../buttons/CancelButton";

interface Properties {
  selectedAdvisor?: AdvisorProtectedView;
  label?: string;
  labelIcon?: IconDefinition;
  callback: (row: AdvisorProtectedView) => void;
  onModalClose?: () => void;
  forceShowModal?: boolean;
  initialQuery: GetAdvisorsListAllApiArg;
}

export default function AdvisorListProtectedViewModal({
  selectedAdvisor,
  label,
  labelIcon,
  callback,
  initialQuery,
  onModalClose,
  forceShowModal,
}: Properties) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const [query, setQuery] = useState(initialQuery);

  const { currentData, isFetching } = useGetAdvisorsListAllQuery(
    { ...query },
    {}
  );
  const [searchValue, setSearchValue] = useState("");
  const searchInputRef = React.createRef<HTMLInputElement>();
  const [selectedRow, setSelectedRow] = useState(selectedAdvisor);

  const setSelectedUser = (advisor: AdvisorProtectedView): void => {
    setSelectedRow(advisor);
  };

  const selectUserAndClose = (advisor: AdvisorProtectedView): void => {
    setSelectedRow(advisor);
    handleChange();
    cleanUp();
  };

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
    return null;
  };

  const searchAdvisors = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key === "Escape"
    ) {
      setShowModal(false);
      return null;
    }

    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return null;
    }

    event.stopPropagation();
    event.preventDefault();

    if (searchValue.length > 2 || searchValue.length === 0) {
      setQuery({
        ...query,
        keyword: searchValue,
        page: 1,
      });
    }

    return null;
  };

  const handleChange = () => {
    setShowModal(false);
    if (selectedRow) {
      callback(selectedRow);
      setSelectedRow(undefined);
    }
  };

  const handleCloseClick = () => {
    cleanUp();
    setSelectedRow(undefined);
    setShowModal(false);
    if (onModalClose) onModalClose();
  };

  const openModal = () => {
    setShowModal(true);
    searchInputRef?.current?.focus();
  };

  const cleanUp = () => {
    setSearchValue("");
    setQuery(initialQuery);
  };

  React.useEffect(() => {
    if (showModal && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [showModal]);

  React.useEffect(() => {
    if (typeof forceShowModal !== "undefined") {
      setShowModal(forceShowModal);
    }
  }, [forceShowModal]);

  return (
    <div className="h-full">
      {typeof forceShowModal === "undefined" ? (
        <div className="flex h-full">
          <Button
            onClick={openModal}
            className="text-sm px-2 py-1 ml-auto"
            icon={labelIcon || faPlus}
          >
            {label || t("app.advisor-select-modal.title")}
          </Button>
        </div>
      ) : undefined}
      <div className={`${showModal ? "block" : "hidden"}`}>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/* content */}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-192 bg-white outline-none focus:outline-none">
              {/* header */}
              <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-2xl font-semibold">
                  <span>{t("app.advisor-select-modal.title")}</span>
                </h3>
                <button
                  type="button"
                  className="p-1 ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={handleCloseClick}
                >
                  <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </button>
              </div>
              {/* body */}
              <div className="relative p-6 pt-2 flex-auto">
                <div className="bg-slate-200 p-4 my-2 mb-4 rounded flex">
                  <div className="flex-grow flex border border-gray-300 rounded">
                    <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="text-gray-600 mt-1.5"
                      />
                    </span>
                    <input
                      ref={searchInputRef}
                      type="text"
                      className="w-full border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                      placeholder={t("table.filter.name") || ""}
                      value={searchValue}
                      onKeyUp={searchAdvisors}
                      onChange={searchFilterChange}
                    />
                  </div>
                  <input
                    type="button"
                    className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                    value={t("button.search") as string}
                    onClick={searchAdvisors}
                  />
                </div>
                <table className="text-sm">
                  <thead>
                    <tr>
                      <th className="!text-left px-4 py-2">
                        <span>{t("table.header.name")}</span>
                      </th>
                      <th className="!text-left px-4 py-2">
                        <span>{t("table.header.email")}</span>
                      </th>
                      <th className="!text-left px-4 py-2">
                        <span>{t("table.header.externalId")}</span>
                      </th>
                    </tr>
                  </thead>
                  {isFetching && !currentData?.items && (
                    <TableBodyLoading cols={3} rows={3} />
                  )}
                  <tbody>
                    {currentData?.items?.map((advisor) => (
                      <tr
                        className={
                          advisor === selectedRow
                            ? "cursor-pointer bg-green-200 hover:bg-green-300"
                            : "cursor-pointer bg-white"
                        }
                        key={advisor.id}
                        onClick={() => setSelectedUser(advisor)}
                        onDoubleClick={() => selectUserAndClose(advisor)}
                      >
                        <td className="!text-left">{advisor.displayName}</td>
                        <td className="!text-left">{advisor.email}</td>
                        <td className="!text-left">{advisor.evidenceNumber}</td>
                      </tr>
                    ))}
                    <tr>
                      <td>
                        {!isFetching && currentData?.count === 0 ? (
                          <Alert className="mt-4">
                            <span>{t("table.noData")}</span>
                          </Alert>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* footer */}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <TablePagination
                  callback={setQuery}
                  query={{
                    ...query,
                    page: query?.page ?? 1,
                  }}
                  count={currentData?.count ?? 0}
                />
                <div className="ml-auto flex gap-4">
                  <AddButton onClick={handleChange} disabled={!selectedRow} />
                  <CancelButton onClick={handleCloseClick} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" />
      </div>
    </div>
  );
}

AdvisorListProtectedViewModal.defaultProps = {
  selectedAdvisor: undefined,
  label: "",
  labelIcon: undefined,
  onModalClose: undefined,
  forceShowModal: undefined,
};
