import React, { useEffect, useState } from "react";

import { faUser, faSave } from "@fortawesome/free-solid-svg-icons";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

import {
  Advisor,
  AdvisorPosition,
  AdvisorPositionKey,
} from "../../api/advisors.generated";
import { ValidationError } from "../../api/auth.generated";
import {
  PermissionRuleRights,
  useGetPermissionRuleByIdQuery,
  useDeletePermissionRuleMutation,
  Role,
  useUpdatePermissionRuleMutation,
  useCreatePermissionRuleMutation,
  PermissionRuleUpdateRequest,
  PermissionRule,
  SuccessResponse200,
  PermissionRuleStatusKey,
  RoleUser,
  PermissionRuleTypeKey,
} from "../../api/permissionRule.generated";
import { RoleKey } from "../../api/users.generated";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import DeleteButton from "../../components/buttons/DeleteButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import Input from "../../components/inputs/Input";
import PermissionRulePositionSelect from "../../components/inputs/PermissionRulePositionSelect";
import PermissionRuleTypeSelect from "../../components/inputs/PermissionRuleTypeSelect";
import RoleSelect from "../../components/inputs/RoleSelect";
import ValidatedInput from "../../components/inputs/ValidatedInput";
import AdvisorSelectModal from "../../components/modals/AdvisorSelectModal";
import ConfirmModal from "../../components/modals/ConfirmModal";
import PageHeader from "../../components/page/PageHeader";
import PermissionRuleStatusSelect from "../../components/select/PermissionRuleStatusSelect";
import PermissionRuleType from "../../enums/PermissionRuleType";
import { getRolesFromKeys } from "../../enums/RoleOptions";
import RoleType from "../../enums/RoleType";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import AllRoutes from "../route/Route";
import PermissionList from "../rules/permissions/PermissionList";
import UserRoleTable from "../rules/UsersRoleTable";

function RuleView() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const isEdit = id !== undefined;
  const [isCreating, setIsCreating] = useState(false);
  const { currentData, isFetching, isError } = useGetPermissionRuleByIdQuery(
    {
      "X-Role-Id": getRoleId(),
      id: id ?? "",
    },
    {
      skip: !isEdit,
      refetchOnMountOrArgChange: true,
    }
  );

  const [triggerDelete] = useDeletePermissionRuleMutation();
  const [triggerUpdate] = useUpdatePermissionRuleMutation();
  const [triggerCreate] = useCreatePermissionRuleMutation();

  const getRuleData = (): RuleDataForm => {
    return {
      id: currentData?.id,
      name: currentData?.name,
      typeKey: currentData?.type?.key ?? "pr-all",
      permissionRulesRights: currentData?.permissionRuleRights ?? [],
      statusKey: currentData?.status?.key ?? "prs-active",
      advisorPosition: currentData?.advisor_position?.key,
      role: currentData?.role?.key,
      usersRole: currentData?.users_role ?? [],
      advisor: currentData?.advisor,
    };
  };

  type RuleDataForm = {
    id?: string;
    name?: string;
    typeKey?: PermissionRuleTypeKey;
    permissionRulesRights?: PermissionRuleRights[];
    statusKey?: PermissionRuleStatusKey;
    advisorPosition?: AdvisorPositionKey;
    role?: RoleKey;
    usersRole?: RoleUser[];
    advisor?: Advisor;
  };

  const [localRule, setLocalRule] = useState<RuleDataForm>(getRuleData());
  const [formErrors, setFormErrors] = useState<ValidationError[]>([]);

  const onTypeChange = (value: PermissionRuleTypeKey | undefined) => {
    const rule = {
      ...localRule,
      typeKey: value,
      advisorPosition: currentData?.advisor_position?.key,
      role: currentData?.role?.key,
      usersRole: currentData?.users_role ?? [],
      advisor: currentData?.advisor,
    };

    setLocalRule(rule);
  };

  useEffect(() => {
    setLocalRule(getRuleData());
  }, [currentData]);

  const [deleteModalConfirmation, setDeleteModalConfirmation] = useState(false);

  const deleteRule = () => {
    triggerDelete({ "X-Role-Id": getRoleId(), id: id ?? "" })
      .then(() => {
        toast.success(t("toast.rule.deleting.success"));
        window.history.go(-1);
      })
      .catch(() => toast.error(t("toast.rule.deleting.error")));

    setDeleteModalConfirmation(false);
  };

  const saveRule = () => {
    if (!localRule?.typeKey) {
      toast.error(t("option.type.required"));
      return;
    }

    if (!localRule?.name) {
      toast.error(t("option.name.required"));
      return;
    }

    if (!localRule?.statusKey) {
      toast.error(t("option.status.required"));
      return;
    }

    if (localRule.typeKey === PermissionRuleType.ROLE && !localRule.role) {
      toast.error(t("option.role.required"));
      return;
    }

    if (
      localRule.typeKey === PermissionRuleType.ADVISOR_POSITION &&
      !localRule.advisorPosition
    ) {
      toast.error(t("option.advisorPosition.required"));
      return;
    }
    if (
      (localRule.typeKey === PermissionRuleType.ADVISOR_TREE ||
        localRule.typeKey === PermissionRuleType.ADVISOR_CHILDREN) &&
      !localRule.advisor
    ) {
      toast.error(t("option.advisor.required"));
      return;
    }

    if (
      localRule.typeKey === PermissionRuleType.USER_ROLE &&
      (!localRule.usersRole || localRule.usersRole?.length === 0)
    ) {
      toast.error(t("option.usersRole.required"));
      return;
    }

    if (localRule.permissionRulesRights?.length === 0) {
      toast.error(t("option.permissionRulesRights.required"));
      return;
    }

    const updateData = {
      name: localRule?.name ?? "",
      typeKey: localRule?.typeKey ?? "",
      permissionRuleRights: localRule?.permissionRulesRights?.map(
        (permission) => {
          return {
            createKey: permission?.create?.key ?? "",
            readKey: permission?.read?.key ?? "",
            deleteKey: permission?.delete?.key ?? "",
            updateKey: permission?.update?.key ?? "",
            id: permission?.id ?? undefined,
            permissionRuleID: permission?.id ?? undefined,
            typeKey: permission?.permission_object_type?.key ?? "",
          };
        }
      ),

      // specific by type
      advisorId:
        PermissionRuleType.ADVISOR_TREE === localRule?.typeKey ||
        PermissionRuleType.ADVISOR_CHILDREN === localRule?.typeKey
          ? localRule?.advisor?.id
          : undefined,
      advisorPositionKey:
        localRule?.typeKey === PermissionRuleType.ADVISOR_POSITION
          ? localRule?.advisorPosition
          : undefined,
      roleKey:
        localRule?.typeKey === PermissionRuleType.ROLE
          ? localRule?.role
          : undefined,
      roleUsers:
        localRule?.typeKey === PermissionRuleType.USER_ROLE
          ? localRule?.usersRole?.map((userRoles) => userRoles?.id)
          : undefined,
      statusKey: localRule?.statusKey,
    } as PermissionRuleUpdateRequest;

    if (!isEdit) {
      setIsCreating(true);
      triggerCreate({
        "X-Role-Id": getRoleId(),
        permissionRuleUpdateRequest: updateData,
      }).then(
        ({
          data,
          error,
        }: {
          data?: PermissionRule;
          error?: FetchBaseQueryError | SerializedError;
        }) => {
          if (error) {
            toast.error(t("toast.rule.saving.error"));
            setIsCreating(false);
          }

          if (data) {
            toast.success(t("toast.rule.saving.success"));

            const path = makeRoute(AllRoutes.RULES_EDIT, {
              id: data.id,
            });
            setIsCreating(false);
            navigate(path);
          }
        }
      );
    } else {
      setIsCreating(true);
      triggerUpdate({
        "X-Role-Id": getRoleId(),
        id: id ?? "",
        permissionRuleUpdateRequest: updateData,
      }).then(
        ({
          data,
          error,
        }: {
          data?: SuccessResponse200;
          error?: FetchBaseQueryError | SerializedError;
        }) => {
          if (error) {
            toast.error(t("toast.rule.saving.error"));
            setIsCreating(false);
          }

          if (data) {
            toast.success(t("toast.rule.saving.success"));
            setIsCreating(false);
          }
        }
      );
    }
  };

  if (isError)
    return (
      <div>
        <span>{t("app.page.error.api")}</span>
      </div>
    );

  return (
    <>
      <PageHeader icon={faUser} label={t("app.rules-detail.edit.card.header")}>
        <BackButton onClick={() => window.history.go(-1)} />
        <Button
          icon={faSave}
          form="customerAddForm"
          type="submit"
          onClick={() => saveRule()}
          disabled={isCreating}
        >
          <span>{t("button.save")}</span>
        </Button>
        {isEdit ? (
          <DeleteButton
            onClick={() => setDeleteModalConfirmation(true)}
            disabled={isFetching}
          />
        ) : (
          ""
        )}
      </PageHeader>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <Card>
            <CardHeader
              icon={faUser}
              label={t("app.rules-detail.edit.card.header") || ""}
            />
            <CardBody className="grid grid-cols-2 gap-4">
              <ValidatedInput
                label={t("input.name.label")}
                value={localRule?.name ?? ""}
                field="name"
                callback={(e) =>
                  setLocalRule({ ...localRule, name: e.currentTarget.value })
                }
                errors={formErrors}
              />
              <PermissionRuleTypeSelect
                selectedOption={localRule.typeKey}
                onSelect={onTypeChange}
                disabled={isEdit}
              />
              <PermissionRuleStatusSelect
                selectedOption={localRule?.statusKey}
                onChange={(e) =>
                  setLocalRule({
                    ...localRule,
                    statusKey: e?.currentTarget
                      ?.value as PermissionRuleStatusKey,
                  })
                }
              />
            </CardBody>
            {localRule?.typeKey !== PermissionRuleType.ALL ? (
              <CardFooter
                className={
                  localRule?.typeKey !== PermissionRuleType.USER_ROLE
                    ? "grid grid-cols-2 gap-4"
                    : ""
                }
              >
                {localRule?.typeKey === PermissionRuleType.ADVISOR_POSITION ? (
                  <PermissionRulePositionSelect
                    selectedKey={localRule?.advisorPosition}
                    onSelect={(value: AdvisorPosition) =>
                      setLocalRule({
                        ...localRule,
                        advisorPosition: value?.key,
                      })
                    }
                  />
                ) : null}
                {localRule?.typeKey === PermissionRuleType.ROLE ? (
                  <div>
                    <RoleSelect
                      selectedKey={localRule?.role}
                      onSelect={(value: Role) =>
                        setLocalRule({ ...localRule, role: value?.key })
                      }
                      filterOptions={getRolesFromKeys([
                        RoleType.CENTRAL,
                        RoleType.ADVISOR,
                        RoleType.ASSISTANT,
                      ])}
                    />
                  </div>
                ) : null}
                {localRule?.typeKey === PermissionRuleType.USER_ROLE ? (
                  <UserRoleTable
                    usersRole={localRule?.usersRole ?? []}
                    onChange={(userRole) =>
                      setLocalRule({ ...localRule, usersRole: userRole })
                    }
                  />
                ) : null}
                {localRule?.typeKey === PermissionRuleType.ADVISOR_TREE ||
                localRule?.typeKey === PermissionRuleType.ADVISOR_CHILDREN ? (
                  <>
                    <Input
                      placeholder={t("input.advisor.label") || ""}
                      label={t("input.advisor.label") || ""}
                      value={`${localRule?.advisor?.firstname ?? ""} ${
                        localRule?.advisor?.lastname ?? ""
                      }`}
                      disabled
                    />
                    <div className="flex flex-col items-start">
                      <span className="block h-5 w-full m-2" />{" "}
                      {/* offset fix */}
                      <AdvisorSelectModal
                        callback={(advisor) =>
                          setLocalRule({
                            ...localRule,
                            advisor,
                          })
                        }
                        initialQuery={{
                          page: 1,
                          size: 10,
                          sort: ["lastname", "asc"],
                          keyword: "",
                          hasValidNbsLicence: true,
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </CardFooter>
            ) : null}
          </Card>
        </div>
        <div>
          <PermissionList
            permissionList={localRule?.permissionRulesRights ?? []}
            onUpdate={(permission: PermissionRuleRights[]) =>
              setLocalRule({ ...localRule, permissionRulesRights: permission })
            }
          />
        </div>
      </div>

      <ConfirmModal
        showModal={deleteModalConfirmation}
        text="Naozaj chcete vymazať toto pravidlo?"
        actionConfirm={() => deleteRule()}
        actionNo={() => setDeleteModalConfirmation(false)}
        setShowModal={(value: boolean) => setDeleteModalConfirmation(value)}
        confirmBtnText={t("button.yes")}
        noBtnText={t("button.no")}
      />
    </>
  );
}

export default RuleView;
