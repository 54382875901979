import React, { ChangeEvent } from "react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Role } from "../../api/permissionRule.generated";
import RoleOptions from "../../enums/RoleOptions";

interface Properties {
  selectedKey?: string;
  filterOptions?: Role[];
  onSelect: (item: Role) => void;
}

function PermissionRuleSelect({
  selectedKey,
  onSelect,
  filterOptions,
}: Properties) {
  const { t } = useTranslation();

  const finalSelectedKey = selectedKey ?? RoleOptions[0]?.key ?? "";

  React.useEffect(() => {
    if (!selectedKey && RoleOptions.length > 0) {
      onSelect(RoleOptions[0] as Role);
    }
  }, [selectedKey]);

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const item = RoleOptions.find(
      (option) => option.key === e.target.value
    ) as Role;
    onSelect(item as Role);
  };

  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("select.permission-rule-role.label")}</span>
      </div>
      <select
        className="block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded"
        defaultValue={finalSelectedKey}
        onChange={handleOnChange}
      >
        {filterOptions ? (
          filterOptions.map((item) => (
            <option
              key={`index-${item.key}`}
              label={
                item.name
                  ? t(item.name) || item.key || "{empty_name}"
                  : item.key || "{empty_name}"
              }
              value={item.key}
            >
              {item.name
                ? t(item.name) || item.key || "{empty_name}"
                : item.key || "{empty_name}"}
            </option>
          ))
        ) : (
          <option
            key="index-undefined"
            label={t("option.unselected") || ""}
            value={t("option.unselected") || ""}
          >
            {t("option.unselected") || ""}
          </option>
        )}
      </select>
    </div>
  );
}

PermissionRuleSelect.propTypes = {
  selectedKey: propTypes.string,
};

PermissionRuleSelect.defaultProps = {
  filterOptions: [],
  selectedKey: undefined,
};

export default PermissionRuleSelect;
