import React, { useState, useEffect } from "react";

import {
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import {
  PermissionObjectType,
  PermissionRuleRights,
  PermissionRuleRightsActionType,
} from "../../api/permissionRule.generated";
import PermissionRuleActionTypeKey from "../../enums/PermissionRuleActionTypeKey";
import Button from "../buttons/Button";
import SelectPermissionObjectType from "../inputs/SelectPermissionObjectType";

interface PermissionModalProps {
  showModal: boolean;
  isEdit: boolean;
  permission: PermissionRuleRights | undefined;
  availablePermissionObjectList: PermissionObjectType[];
  onSave: (permission: PermissionRuleRights) => void;
  onClose: () => void;
}

function PermissionModal({
  showModal,
  isEdit,
  permission,
  availablePermissionObjectList,
  onSave,
  onClose,
}: PermissionModalProps) {
  const { t } = useTranslation();

  const [permissionRuleRights, setPermissionRuleRights] = useState<
    PermissionRuleRights | undefined
  >(undefined);

  useEffect(() => {
    const finalPermission: PermissionRuleRights = permission ?? {
      create: { key: "prra-none" },
      update: { key: "prra-none" },
      delete: { key: "prra-none" },
      read: { key: "prra-none" },
      permission_object_type: availablePermissionObjectList[0],
    };
    setPermissionRuleRights(finalPermission);
  }, [permission, availablePermissionObjectList]);

  const handleSave = () => {
    if (
      !permissionRuleRights?.create &&
      !permissionRuleRights?.read &&
      !permissionRuleRights?.update &&
      !permissionRuleRights?.delete
    ) {
      onClose();
      return;
    }

    onSave(permissionRuleRights as PermissionRuleRights);
  };

  const handleCloseClick = () => {
    setPermissionRuleRights(undefined);
    onClose();
  };

  if (!permissionRuleRights) {
    return null;
  }

  return (
    <div className={`${showModal ? "block" : "hidden"}`}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
        <div className="relative my-6 mx-auto w-full max-w-4xl">
          {/* content */}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white text-gray-700 outline-none focus:outline-none">
            {/* header */}
            <div className="p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl inline-block font-semibold">
                <span>{t("modal.permission.role.title")}</span>
              </h3>
              <div className="float-right flex">
                <button
                  type="button"
                  className="p-1 inline-flex ml-auto bg-transparent border-0 text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={handleCloseClick}
                >
                  <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                </button>
              </div>
            </div>
            {/* body */}
            <div className="relative p-6 pt-2 flex-auto">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th className="text-left p-4">
                          <span>{t("app.table.role.permission.title")}</span>
                        </th>
                        <th className="text-center p-4">
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-spf-green"
                          />
                        </th>
                        <th className="text-center p-4">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-spf-gray"
                          />
                        </th>
                        <th className="text-center p-4">
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            className="text-spf-red"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <OperationRow
                        title={t("app.table.role.permission.read")}
                        type="read"
                        actionType={permissionRuleRights.read}
                        onChange={(rule: PermissionRuleRightsActionType) =>
                          setPermissionRuleRights({
                            ...permissionRuleRights,
                            read: rule,
                          })
                        }
                      />
                      <OperationRow
                        title={t("app.table.role.permission.create")}
                        type="create"
                        actionType={permissionRuleRights.create}
                        onChange={(rule: PermissionRuleRightsActionType) =>
                          setPermissionRuleRights({
                            ...permissionRuleRights,
                            create: rule,
                          })
                        }
                      />
                      <OperationRow
                        title={t("app.table.role.permission.update")}
                        type="update"
                        actionType={permissionRuleRights.update}
                        onChange={(rule: PermissionRuleRightsActionType) =>
                          setPermissionRuleRights({
                            ...permissionRuleRights,
                            update: rule,
                          })
                        }
                      />
                      <OperationRow
                        title={t("app.table.role.permission.delete")}
                        type="delete"
                        actionType={permissionRuleRights.delete}
                        onChange={(rule: PermissionRuleRightsActionType) =>
                          setPermissionRuleRights({
                            ...permissionRuleRights,
                            delete: rule,
                          })
                        }
                      />
                    </tbody>
                  </table>
                </div>
                <div>
                  <SelectPermissionObjectType
                    availableOptions={
                      isEdit
                        ? [permissionRuleRights?.permission_object_type ?? {}]
                        : availablePermissionObjectList
                    }
                    selectedOption={
                      isEdit
                        ? permissionRuleRights?.permission_object_type?.key
                        : undefined
                    }
                    disabled={isEdit}
                    onSelect={(pot: PermissionObjectType) => {
                      setPermissionRuleRights({
                        ...permissionRuleRights,
                        permission_object_type: pot,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
              <div className="flex flex-row gap-4">
                <Button
                  onClick={() => {
                    handleSave();
                  }}
                  disabled={!permissionRuleRights.permission_object_type}
                >
                  <span>{t("button.save")}</span>
                </Button>
                <Button
                  className="bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring-gray-300"
                  onClick={handleCloseClick}
                >
                  <span>{t("button.close")}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />
    </div>
  );
}

function OperationRow({
  title,
  type,
  actionType,
  onChange,
}: {
  title: string;
  type: string;
  actionType: PermissionRuleRightsActionType | undefined;
  onChange: (rule: PermissionRuleRightsActionType) => void;
}) {
  const [rule, setRule] = useState<PermissionRuleRightsActionType | undefined>(
    actionType
  );
  useEffect(() => {
    setRule(actionType);
  }, [actionType]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const newValues = { ...rule };
    newValues.key = value ? (value as PermissionRuleActionTypeKey) : undefined;

    setRule(newValues);
    onChange(newValues);
  };

  return (
    <tr className="cursor-auto hover:bg-white">
      <td className="text-left text-spf-primary">
        <span>{title}</span>
      </td>
      <td className="text-center">
        <input
          type="radio"
          name={type}
          checked={rule?.key === PermissionRuleActionTypeKey.ADD}
          onChange={(e) => handleChange(e, PermissionRuleActionTypeKey.ADD)}
        />
      </td>
      <td className="text-center">
        <input
          type="radio"
          name={type}
          checked={
            rule === undefined || rule?.key === PermissionRuleActionTypeKey.NONE
          }
          onChange={(e) => handleChange(e, PermissionRuleActionTypeKey.NONE)}
        />
      </td>
      <td className="!text-center">
        <input
          type="radio"
          name={type}
          checked={rule?.key === PermissionRuleActionTypeKey.REMOVE}
          onChange={(e) => handleChange(e, PermissionRuleActionTypeKey.REMOVE)}
        />
      </td>
    </tr>
  );
}

export default PermissionModal;
