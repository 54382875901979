import React, { ChangeEvent } from "react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  PermissionObjectType,
  PermissionObjectTypeKey,
} from "../../api/permissionRule.generated";
import PermissionObjectSelectOptions from "../../enums/PermissionObjectSelectOptions";
import SelectOption from "../../enums/SelectOption";

interface Properties {
  selectedOption?: PermissionObjectTypeKey;
  availableOptions: PermissionObjectType[];
  onSelect: (item: PermissionObjectType) => void;
  disabled?: boolean;
}

function SelectPermissionObjectType({
  selectedOption,
  availableOptions,
  onSelect,
  disabled,
}: Properties) {
  const { t } = useTranslation();

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const item = PermissionObjectSelectOptions.find(
      (option) => option.key === e.target.value
    ) as SelectOption<PermissionObjectTypeKey>;
    onSelect(item as PermissionObjectType);
  };

  const finalSelectedOption = selectedOption ?? availableOptions[0]?.key;

  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("app.form.permissionObjectType")}</span>
      </div>
      <select
        className="block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded"
        defaultValue={finalSelectedOption}
        onChange={handleOnChange}
        disabled={disabled}
      >
        {availableOptions.map((item) => (
          <option
            key={`index-${item.key}`}
            label={item.name ? t(item.name) ?? "" : ""}
            value={item.key}
          >
            {item.name ? t(item.name) : ""}
          </option>
        ))}
      </select>
    </div>
  );
}

SelectPermissionObjectType.propTypes = {
  selectedOption: propTypes.string,
};

SelectPermissionObjectType.defaultProps = {
  selectedOption: undefined,
  disabled: false,
};

export default SelectPermissionObjectType;
