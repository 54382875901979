import React, { FormEvent, useState } from "react";

import { faList, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import {
  useComputePermissionsAllMutation,
  useGetComputedRightsQuery,
} from "../../api/permissionRule.generated";
import Alert from "../../components/alerts/Alert";
import CountBadge from "../../components/badges/CountBadge";
import Button from "../../components/buttons/Button";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import PageHeader from "../../components/page/PageHeader";
import { ColoredCharacters } from "../../components/permissions/ColoredCharacters";
import PermissionOperationTypeKey from "../../enums/PermissionOperationType";
import PermissionType from "../../enums/PermissionType";
import { getRoleName } from "../../enums/RoleOptions";
import { useMatrixListQuery } from "../../hooks/matrix";
import { useGetComputedCrudProperty } from "../../hooks/permissions";
import { getRoleId } from "../../hooks/role";
import { makeRoute } from "../../hooks/route";
import AllRoutes from "../route/Route";
import TableBodyLoading from "../table/TableBodyLoading";
import TablePageSize from "../table/TablePageSize";
import TablePagination from "../table/TablePagination";

function MatrixListView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery] = useMatrixListQuery();
  const [searchValue, setSearchValue] = useState(query.keyword);

  const [computePermissionsAll] = useComputePermissionsAllMutation();

  const { currentData, isFetching, isError, refetch } =
    useGetComputedRightsQuery(
      { ...query, "X-Role-Id": getRoleId() },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const redirectToDetail = (id: string) => {
    const path = makeRoute(AllRoutes.RULES_MATRIX_DETAIL, {
      id,
    });
    navigate(path);
  };

  const searchFilterChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
  };

  const searchRules = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keyup" &&
      (event as React.KeyboardEvent).key !== "Enter"
    ) {
      return null;
    }

    return setQuery({
      ...query,
      page: 1,
      keyword: searchValue,
    });
  };

  const operationItems = [
    {
      name: "table.header.clients",
      key: PermissionType.CUSTOMERS,
    },
    {
      name: "table.header.links",
      key: PermissionType.EXTERNAL_LINKS,
    },
    {
      name: "table.header.zuz",
      key: PermissionType.ZUZ,
    },
    {
      name: "table.header.documents",
      key: PermissionType.CHECK,
    },
    {
      name: "table.header.identifications",
      key: PermissionType.IDENTIFICATIONS,
    },
    {
      name: "table.header.users",
      key: PermissionType.USERS,
    },
  ];

  if (isError) return <div>{t("app.page.error.api") || ""}</div>;

  return (
    <>
      <PageHeader
        icon={AllRoutes.RULES_MATRIX_LIST.icon}
        label={t(AllRoutes.RULES_MATRIX_LIST.name ?? "") || ""}
      >
        <Button
          onClick={() => {
            computePermissionsAll({
              "Accept-Language": "en",
              "X-Role-Id": getRoleId(),
            })
              .unwrap()
              .then(() => {
                refetch();
                toast.success(t("app.page.rules-matrix.recalculate.success"));
              })
              .catch((err) => {
                toast.error(t("app.page.rules-matrix.recalculate.error"));
              });
          }}
        >
          {t("app.page.rules-matrix.recalculate.button.label") || ""}
        </Button>
      </PageHeader>

      <Card>
        <CardHeader
          icon={faList}
          label={t("app.page.rules-matrix.heading") || ""}
        >
          <span className="float-right">
            <CountBadge count={currentData?.count} />
          </span>
        </CardHeader>
        <CardBody>
          <div className="flex p-4 mb-4 bg-slate-200 rounded-lg">
            <div className="flex ml-auto">
              <div className="flex border border-gray-300 rounded">
                <span className="w-8 text-center text-gray-300 px-1 border-gray-300 border-r bg-white rounded-l">
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-gray-600 mt-1.5"
                  />
                </span>
                <input
                  type="text"
                  className="border-none text-gray-700 px-4 text-sm py-1 outline-0 rounded-r"
                  placeholder={t("table.filter.name") || ""}
                  value={searchValue}
                  onKeyUp={(e) => searchRules(e)}
                  onChange={(e) => searchFilterChange(e)}
                />
              </div>
              <input
                type="button"
                className="ml-1 border border-gray-300 bg-white text-gray-700 px-4 text-sm py-1 outline-0 rounded"
                value={t("button.search") || ""}
                onClick={(e) => searchRules(e)}
              />
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <td>
                  <span>{t("table.header.name")}</span>
                </td>
                <td>
                  <span>{t("table.header.role")}</span>
                </td>
                {operationItems.map((operation) => (
                  <td
                    className="!text-center"
                    key={`table-header-${operation.key}`}
                  >
                    {t(operation.name) || ""}
                  </td>
                ))}
              </tr>
            </thead>
            {isFetching && !currentData ? (
              <TableBodyLoading cols={1} rows={query.size ?? 10} />
            ) : (
              <tbody>
                {currentData
                  ? currentData?.items?.map((permission) => (
                      <tr
                        key={`index-${permission.id}`}
                        className="cursor-pointer"
                        onClick={() => redirectToDetail(permission.id ?? "")}
                      >
                        <td className="text-spf-primary">
                          {permission.displayName}
                        </td>
                        <td className="text-spf-primary">
                          {getRoleName(permission.roleKey)}
                        </td>
                        {operationItems.map((operation) => (
                          <td
                            className="text-spf-primary !text-center"
                            key={`table-body-${operation.key}`}
                          >
                            <ColoredCharacters
                              create={getComputedCrudProperty(
                                permission.computedCrud,
                                operation.key,
                                PermissionOperationTypeKey.CREATE
                              )}
                              read={getComputedCrudProperty(
                                permission.computedCrud,
                                operation.key,
                                PermissionOperationTypeKey.READ
                              )}
                              update={getComputedCrudProperty(
                                permission.computedCrud,
                                operation.key,
                                PermissionOperationTypeKey.UPDATE
                              )}
                              delete={getComputedCrudProperty(
                                permission.computedCrud,
                                operation.key,
                                PermissionOperationTypeKey.DELETE
                              )}
                            />
                          </td>
                        ))}
                      </tr>
                    ))
                  : t("table.noData") || ""}
              </tbody>
            )}
          </table>
          {!isFetching && currentData?.count === 0 ? (
            <Alert className="mt-4">{t("table.noData") || ""}</Alert>
          ) : (
            <span />
          )}
        </CardBody>
        <CardFooter>
          <div className="">
            <TablePagination
              callback={setQuery}
              query={query}
              count={currentData?.count ?? 0}
            />
          </div>
          <div className="ml-auto">
            <TablePageSize query={query} callback={setQuery} />
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default MatrixListView;
