import React, { useEffect, useState } from "react";

import {
  faUser,
  faLock,
  faSave,
  faUsers,
  faIdCard,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import ReactRouterPrompt from "react-router-prompt";
import { toast } from "react-toastify";

import { ValidationError } from "../../api/auth.generated";
import {
  GenderKey,
  CustomerTypeKey,
  CustomerUpdateRequest,
  useCreateCustomerMutation,
} from "../../api/customers.generated";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import CancelButton from "../../components/buttons/CancelButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardHeader from "../../components/card/CardHeader";
import SelectCustomerType from "../../components/inputs/SelectCustomerType";
import SelectGender from "../../components/inputs/SelectGender";
import ValidatedCheckbox from "../../components/inputs/ValidatedCheckbox";
import ValidatedInput from "../../components/inputs/ValidatedInput";
import ValidatedInputDate from "../../components/inputs/ValidatedInputDate";
import ValidatedInputPhone from "../../components/inputs/ValidatedInputPhone";
import ConfirmModal from "../../components/modals/ConfirmModal";
import CustomerFormReturnToDocumentModal from "../../components/modals/CustomerFormReturnToDocumentModal";
import FormIsDirtyModal from "../../components/modals/FormIsDirtyModal";
import PageHeader from "../../components/page/PageHeader";
import Country from "../../enums/Country";
import CustomerType, { CT_FO, CT_FOP, CT_PO } from "../../enums/CustomerType";
import { userStatus } from "../../enums/Status";
import useClientUtils from "../../hooks/clientUtils";
import { validatePhoneNumber } from "../../hooks/customers";
import useFormatters from "../../hooks/formatter";
import useHelpers from "../../hooks/helper";
import { getRoleId } from "../../hooks/role";
import { makeRoute, parseUrl } from "../../hooks/route";
import AllRoutes from "../route/Route";

type CustomerForm = {
  [key: string]: any;
  advisorId?: never[];
  pin: string;
  email: string;
  firstname: string;
  lastname: string;
  dateOfBirth: string;
  statusKey: string;
  authorizedPersonPosition: string;
  businessName: string;
  contractAgreement: boolean;
  cop: string;
  displayName: string;
  foreignClient: boolean;
  genderKey: GenderKey | undefined;
  opValidUntil: undefined;
  passportNumber: string;
  pasValidUntil: undefined;
  otherIDCardType: string;
  otherIDCardNumber: string;
  otherIDCardValidUntil: undefined;
  icmId: string;
  icmStatus: boolean;
  personalMarketingInformation: boolean;
  phoneNumber: string;
  title: string;
  typeKey: CustomerTypeKey;
  ico: string;
  profession: string;
  citizenship: string;
  permanentAddress: {
    city: string;
    countryKey: "sk";
    street: string;
    zip: string;
  };
  businessAddress: {
    city: string;
    countryKey: "sk";
    street: string;
    zip: string;
  };
  correspondenceAddress: {
    city: string;
    countryKey: "sk";
    street: string;
    zip: string;
  };
  contractSignedAt: undefined;
  dateOfConsent: undefined;
  note: string;
};

function CustomerAddView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { testEmail } = useHelpers();
  const { isIdentificationNumberSameAsBirthDate } = useClientUtils();
  const { formatEmail } = useFormatters();
  const [customerCreatedId, setCustomerCreatedId] = useState(null);
  const [showFormIsDirtyModal, setShowFormIsDirtyModal] = useState(false);
  const [blockNavigation, setBlockNavigation] = useState(false);
  const { formatPin } = useFormatters();

  const [form, setForm] = useState<CustomerForm>({
    advisorId: [],
    pin: "",
    email: "",
    firstname: "",
    lastname: "",
    dateOfBirth: "",
    statusKey: userStatus[0].key,

    authorizedPersonPosition: "",
    businessName: "",
    contractAgreement: false,
    cop: "",
    displayName: "",
    foreignClient: false,
    genderKey: undefined,
    opValidUntil: undefined,
    passportNumber: "",
    pasValidUntil: undefined,
    otherIDCardType: "",
    otherIDCardNumber: "",
    otherIDCardValidUntil: undefined,
    icmId: "",
    icmStatus: false,
    personalMarketingInformation: false,
    phoneNumber: "+421",
    title: "",
    typeKey: CustomerType[0].key,
    ico: "",
    profession: "",
    citizenship: "",
    permanentAddress: {
      city: "",
      countryKey: Country[0].key,
      street: "",
      zip: "",
    },
    businessAddress: {
      city: "",
      countryKey: Country[0].key,
      street: "",
      zip: "",
    },
    correspondenceAddress: {
      city: "",
      countryKey: Country[0].key,
      street: "",
      zip: "",
    },
    contractSignedAt: undefined,
    dateOfConsent: undefined,
    note: "",
  });

  const [initialFormState] = useState<CustomerForm>(form);

  const getDirtyFields = (formData: CustomerForm) => {
    const dirtyFields: string[] = [];
    for (const [key, value] of Object.entries(formData)) {
      const isDirty = value !== initialFormState[key];
      if (isDirty) {
        dirtyFields.push(key);
      }
    }

    return dirtyFields;
  };

  useEffect(() => {
    if (form) {
      const dirtyFields = getDirtyFields(form);
      if (dirtyFields.length > 0) {
        setBlockNavigation(true);
      } else {
        setBlockNavigation(false);
      }
    }
  }, [form]);

  useEffect(() => {
    if (
      sessionStorage.getItem("newClientSelectedType") &&
      sessionStorage.getItem("newClientInputValue")
    ) {
      const type = sessionStorage.getItem("newClientSelectedType") || "";
      const value = sessionStorage.getItem("newClientInputValue") || "";

      setForm({
        ...form,
        typeKey: type as CustomerTypeKey,
        pin: type === CT_FO ? value : "",
        ico: type === CT_FOP || type === CT_PO ? value : "",
      });

      sessionStorage.removeItem("newClientSelectedType");
      sessionStorage.removeItem("newClientInputValue");
    }
  });

  const [formErrors, setFormErrors] = useState<ValidationError[]>([]);

  const [triggerCreate] = useCreateCustomerMutation();
  const [isCreating, setIsCreating] = useState(false);

  const [showReturnToDocumentModal, setShowReturnToDocumentModal] =
    useState(false);
  const [showPINModal, setShowPINModal] = useState(false);

  const getNewObject = (): CustomerUpdateRequest => {
    return {
      ...form,
      dateOfBirth: form?.dateOfBirth,
    };
  };

  const handleAndSave = (e: React.FormEvent<HTMLFormElement>) => {
    setIsCreating(true);
    setBlockNavigation(false);
    e.preventDefault();

    const newObject = getNewObject();

    if (!validate()) {
      setIsCreating(false);
      return;
    }

    if (
      !isIdentificationNumberSameAsBirthDate(
        newObject?.pin ?? "",
        newObject?.dateOfBirth ?? "",
        newObject?.genderKey ?? ""
      )
    ) {
      setShowPINModal(true);
      return;
    }

    save();
  };

  const save = async () => {
    const newObject = getNewObject();
    setBlockNavigation(false);

    triggerCreate({
      "X-Role-Id": getRoleId(),
      customerUpdateRequest: newObject,
    }).then((response: any) => {
      if (response.error) {
        setBlockNavigation(true);
        setFormErrors(response.error.data.data);
        setIsCreating(false);
        return toast.warning(t("app.customer.add.failed"), {
          closeOnClick: true,
        });
      }

      if (response.data.id) {
        setCustomerCreatedId(response.data.id);
      }

      setIsCreating(false);
      toast.success(t("app.customer.add.success"));
      const parsedQueryParams = parseUrl(location.search);
      if (
        Object.keys(parsedQueryParams).includes("document") &&
        Object.keys(parsedQueryParams).includes("customerType")
      ) {
        setShowReturnToDocumentModal(true);
      } else {
        return navigate(
          makeRoute(AllRoutes.CUSTOMERS_EDIT, { id: response?.data?.id })
        );
      }
      return "";
    });
  };

  const validate = () => {
    const newFormErrors: ValidationError[] = [];

    // validate
    if (form.firstname === "") {
      newFormErrors.push({
        field: "firstname",
        message: t("app.customerForm.firstname.required") || "",
      });
    }

    if (form.lastname === "") {
      newFormErrors.push({
        field: "lastname",
        message: t("app.customerForm.lastname.required") || "",
      });
    }

    if (form.email === "") {
      newFormErrors.push({
        field: "email",
        message: t("app.customerForm.email.required") || "",
      });
    }

    if (form.email !== "" && !testEmail(form.email)) {
      newFormErrors.push({
        field: "email",
        message: t("app.customerForm.email.formatError") || "",
      });
    }

    setFormErrors(newFormErrors);

    return !(newFormErrors.length > 0);
  };

  const handlePhoneInputChange = (field: string, e: any) => {
    let newErrors = [...formErrors];
    const key = newErrors.findIndex((item) => item.field === field);
    newErrors = newErrors.filter((item, i) => i !== key);
    const validatePhoneNumberRes = validatePhoneNumber(e.target.value);

    setForm({ ...form, [field]: validatePhoneNumberRes.field });
    if (validatePhoneNumberRes.message) {
      newErrors.push({
        field,
        message: validatePhoneNumberRes.message,
      });
    }
    setFormErrors(newErrors);
  };

  const handleInputChange = (field: string, e: any) => {
    let { value } = e.target;

    if (field === "pin") {
      value = formatPin(value);
    }

    if (field === "email") {
      value = formatEmail(value);
    }

    setForm({ ...form, [field]: value });
  };

  const handleCheckboxChange = (field: string, e: any) => {
    setForm({ ...form, [field]: e.target.checked });
  };

  const handleDateChange = (field: string, date: Date) => {
    setForm({ ...form, [field]: date });
  };

  const handleNoReturnToDocument = () => {
    if (customerCreatedId) {
      return navigate(
        makeRoute(AllRoutes.CUSTOMERS_EDIT, { id: customerCreatedId || "0" })
      );
    }
    return null;
  };

  const handleBack = () => {
    const dirtyFields = getDirtyFields(form);
    if (dirtyFields.length > 0) {
      setShowFormIsDirtyModal(true);
    } else {
      navigate(makeRoute(AllRoutes.CUSTOMERS_LIST));
    }
  };

  return (
    <>
      <PageHeader icon={faUser} label={t("app.customer.create.header")}>
        <BackButton
          onClick={() => {
            handleBack();
          }}
        />
        <Button
          icon={faSave}
          form="customerAddForm"
          type="submit"
          disabled={isCreating}
          dataTestId="/admin/customers/add/btn-add"
        >
          <span>{t("button.addNewCustomer")}</span>
        </Button>
      </PageHeader>

      <form
        className="grid grid-cols-1 xl:grid-cols-2 gap-4"
        onSubmit={handleAndSave}
        id="customerAddForm"
        autoComplete="false"
      >
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              icon={faUser}
              label={t("app.customer.edit.card.customer.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-5 gap-4">
                <ValidatedInput
                  label={t("app.form.title")}
                  field="title"
                  type="text"
                  value={form.title}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleInputChange("title", e)}
                />
                <div className="col-span-2">
                  <ValidatedInput
                    label={t("app.form.firstname")}
                    field="firstname"
                    type="text"
                    value={form.firstname}
                    errors={formErrors}
                    required
                    callback={(e) => handleInputChange("firstname", e)}
                  />
                </div>
                <div className="col-span-2">
                  <ValidatedInput
                    label={t("app.form.lastname")}
                    field="lastname"
                    type="text"
                    value={form.lastname}
                    errors={formErrors}
                    required
                    callback={(e) => handleInputChange("lastname", e)}
                  />
                </div>
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInputPhone
                  label={t("app.form.phoneNumber")}
                  value={form.phoneNumber}
                  required={false}
                  placeholder="XXXXXXXXX"
                  field="phoneNumber"
                  errors={formErrors}
                  callback={(e) => handlePhoneInputChange("phoneNumber", e)}
                />

                <ValidatedInput
                  label={t("app.form.email")}
                  field="email"
                  type="email"
                  value={form.email}
                  errors={formErrors}
                  required
                  callback={(e) => handleInputChange("email", e)}
                />
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInputDate
                  label={t("app.form.dateOfBirth")}
                  field="dateOfBirth"
                  value={form.dateOfBirth}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleDateChange("dateOfBirth", e)}
                />
                <ValidatedInput
                  label={t("app.form.pin")}
                  field="pin"
                  type="text"
                  value={form.pin}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleInputChange("pin", e)}
                />
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <SelectCustomerType
                  selectedOption={form?.typeKey}
                  onChange={(e) => handleInputChange("typeKey", e)}
                />
                <ValidatedInput
                  label={t("app.form.citizenship")}
                  field="citizenship"
                  type="text"
                  value={form.citizenship}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleInputChange("citizenship", e)}
                />
                <SelectGender
                  selectedOption={{
                    key: form?.genderKey,
                  }}
                  onChange={(e) => handleInputChange("genderKey", e)}
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faIdCard}
              label={t("card.documents.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.cop")}
                  field="cop"
                  type="text"
                  value={form.cop}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleInputChange("cop", e)}
                />
                <ValidatedInputDate
                  label={t("app.form.opValidUntil")}
                  field="opValidUntil"
                  value={form.opValidUntil}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleDateChange("opValidUntil", e)}
                />
                <ValidatedInput
                  label={t("app.form.passportNumber")}
                  field="passportNumber"
                  type="text"
                  value={form.passportNumber}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleInputChange("passportNumber", e)}
                />
                <ValidatedInputDate
                  label={t("app.form.pasValidUntil")}
                  field="pasValidUntil"
                  value={form.pasValidUntil}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleDateChange("pasValidUntil", e)}
                />
              </div>
              <div className="py-1 grid grid-cols-3 gap-4">
                <ValidatedInput
                  label={t("app.form.otherIdCardType")}
                  field="otherIdCardType"
                  type="text"
                  value={form.otherIDCardType}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleInputChange("otherIDCardType", e)}
                />
                <ValidatedInput
                  label={t("app.form.otherIdCardNumber")}
                  field="otherIdCardNumber"
                  type="text"
                  value={form.otherIDCardNumber}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleInputChange("otherIDCardNumber", e)}
                />
                <ValidatedInputDate
                  label={t("app.form.otherIdCardValidUntil")}
                  field="otherIDCardValidUntil"
                  value={form.otherIDCardValidUntil}
                  errors={formErrors}
                  required={false}
                  callback={(e) => handleDateChange("otherIDCardValidUntil", e)}
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader icon={faUsers} label={t("card.firm.header") || ""} />
            <CardBody>
              <ValidatedInput
                label={t("app.form.businessName")}
                field="businessName"
                type="text"
                value={form.businessName}
                errors={formErrors}
                required={false}
                callback={(e) => handleInputChange("businessName", e)}
              />
              <ValidatedInput
                label={t("app.form.ico")}
                field="ico"
                type="text"
                value={form.ico}
                errors={formErrors}
                required={false}
                callback={(e) => handleInputChange("ico", e)}
              />
            </CardBody>
          </Card>
        </div>
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              icon={faLock}
              label={t("card.permanentAddress.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.street")}
                  field="street"
                  type="text"
                  value={form.permanentAddress.street}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      permanentAddress: {
                        ...form?.permanentAddress,
                        street: e.currentTarget.value,
                      },
                    })
                  }
                />
                <ValidatedInput
                  label={t("app.form.city")}
                  field="city"
                  type="text"
                  value={form.permanentAddress.city}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      permanentAddress: {
                        ...form?.permanentAddress,
                        city: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.zip")}
                  field="zip"
                  type="text"
                  value={form.permanentAddress.zip}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      permanentAddress: {
                        ...form?.permanentAddress,
                        zip: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faLock}
              label={t("card.correspondenceAddress.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.street")}
                  field="street"
                  type="text"
                  value={form.correspondenceAddress.street}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      correspondenceAddress: {
                        ...form?.correspondenceAddress,
                        street: e.currentTarget.value,
                      },
                    })
                  }
                />
                <ValidatedInput
                  label={t("app.form.city")}
                  field="city"
                  type="text"
                  value={form.correspondenceAddress.city}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      correspondenceAddress: {
                        ...form?.correspondenceAddress,
                        city: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.zip")}
                  field="zip"
                  type="text"
                  value={form.correspondenceAddress.zip}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      correspondenceAddress: {
                        ...form?.correspondenceAddress,
                        zip: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faLock}
              label={t("card.businessAddress.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.street")}
                  field="street"
                  type="text"
                  value={form.businessAddress.street}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      businessAddress: {
                        ...form?.businessAddress,
                        street: e.currentTarget.value,
                      },
                    })
                  }
                />
                <ValidatedInput
                  label={t("app.form.city")}
                  field="city"
                  type="text"
                  value={form.businessAddress.city}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      businessAddress: {
                        ...form?.businessAddress,
                        city: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.zip")}
                  field="zip"
                  type="text"
                  value={form.businessAddress.zip}
                  errors={formErrors}
                  required={false}
                  callback={(e: React.FormEvent<HTMLInputElement>) =>
                    setForm({
                      ...form,
                      businessAddress: {
                        ...form?.businessAddress,
                        zip: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faFileContract}
              label={t("card.other.header") || ""}
            />
            <CardBody>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <ValidatedCheckbox
                    label={t("app.form.contractAgreementSignedOn")}
                    field="contractAgreement"
                    value={form.contractAgreement}
                    errors={formErrors}
                    required={false}
                    callback={(e) =>
                      handleCheckboxChange("contractAgreement", e)
                    }
                    disabled
                  />
                </div>
                <div className="flex justify-end">
                  <ValidatedInputDate
                    label=""
                    className="w-[220px]"
                    field="contractSignedAt"
                    value={form.contractSignedAt}
                    errors={formErrors}
                    required={false}
                    callback={(e) => handleDateChange("contractSignedAt", e)}
                    disabled
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <ValidatedCheckbox
                    label={t(
                      "app.form.personalMarketingInformationGeneratedOn"
                    )}
                    field="personalMarketingInformation"
                    value={form.personalMarketingInformation}
                    errors={formErrors}
                    required={false}
                    callback={(e) =>
                      handleCheckboxChange("personalMarketingInformation", e)
                    }
                    disabled
                  />
                </div>
                <div className="flex justify-end">
                  <ValidatedInputDate
                    label=""
                    className="ml-[40px] w-[220px]"
                    field="dateOfConsett"
                    value={form.dateOfConsent}
                    errors={formErrors}
                    required={false}
                    callback={(e) => handleDateChange("dateOfConsent", e)}
                    disabled
                  />
                </div>
              </div>
              <ValidatedCheckbox
                label={t("app.form.foreignClient")}
                field="foreignClient"
                value={form.foreignClient}
                errors={formErrors}
                required={false}
                callback={(e) => handleCheckboxChange("foreignClient", e)}
              />
              <ValidatedInput
                label={t("app.form.profession")}
                field="profession"
                type="text"
                value={form.profession}
                errors={formErrors}
                required={false}
                callback={(e) => handleInputChange("profession", e)}
              />
              <ValidatedInput
                label={t("app.form.authorizedPersonPosition")}
                field="authorizedPersonPosition"
                type="text"
                value={form.authorizedPersonPosition}
                errors={formErrors}
                required={false}
                callback={(e) =>
                  handleInputChange("authorizedPersonPosition", e)
                }
              />
              <ValidatedInput
                label={t("app.form.note")}
                field="note"
                type="text"
                value={form.note}
                errors={formErrors}
                required={false}
                callback={(e) => handleInputChange("note", e)}
              />
            </CardBody>
          </Card>
        </div>
      </form>
      <CustomerFormReturnToDocumentModal
        showModal={showReturnToDocumentModal}
        setShowModal={setShowReturnToDocumentModal}
        customerId={customerCreatedId ?? ""}
        callbackNo={handleNoReturnToDocument}
      />
      <ConfirmModal
        showModal={showPINModal}
        setShowModal={setShowPINModal}
        text={t("modal.dateOfBirthNotMatch")}
        confirmBtnText={t("button.save")}
        noBtnText={t("button.back")}
        actionConfirm={() => {
          setShowPINModal(false);
          save();
          setIsCreating(false);
        }}
        actionNo={() => {
          setShowPINModal(false);
          setIsCreating(false);
        }}
        showX
      />
      <FormIsDirtyModal
        show={showFormIsDirtyModal}
        setShow={setShowFormIsDirtyModal}
        callback={() => {
          navigate(makeRoute(AllRoutes.CUSTOMERS_LIST));
        }}
      />
      <ReactRouterPrompt when={blockNavigation}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <>
              <div className="opacity-25 fixed inset-0 z-40 bg-black" />
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto mx-auto max-w-3xl">
                  {/* content */}
                  <div className="rounded-lg shadow-xl relative flex flex-col bg-white w-96">
                    {/* header */}
                    <div className="flex flex-cols px-4 py-4" />
                    {/* body */}
                    <div className="px-4 py-4 my-4 text-blueGray-500">
                      <span>{t("formIsDirtyModal.question")}</span>
                    </div>
                    <div className="border-gray-300 text-center">
                      <div className="px-4 py-4">
                        <Button className="mr-1" onClick={onConfirm}>
                          <span>{t("button.yes")}</span>
                        </Button>
                        <CancelButton onClick={onCancel} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </ReactRouterPrompt>
    </>
  );
}

export default CustomerAddView;
