import React, { useState } from "react";

import {
  faTimes,
  IconDefinition,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { openDocument } from "../../api/auth";
import { Customer } from "../../api/customers.generated";
import {
  useGetDocumentsQuery,
  Document,
  AdvisorProtectedView,
} from "../../api/documents.generated";
import { SIGNED } from "../../enums/DocumentStatusKey";
import { DocumentTypeKeys } from "../../enums/DocumentTypes";
import { ALL } from "../../enums/FilterLevelKey";
import TableBodyLoading from "../../features/table/TableBodyLoading";
import useFormatters from "../../hooks/formatter";
import { getRoleId } from "../../hooks/role";
import { DocumentCheckAttachment } from "../../models/DocumentCheckAttachment";
import Alert from "../alerts/Alert";
import Button from "../buttons/Button";

interface Properties {
  btnLabel: string;
  modalTitle: string;
  btnIcon?: IconDefinition;
  callback: (document: DocumentCheckAttachment) => void;
  customer?: Customer;
  advisor?: AdvisorProtectedView;
}

export default function ZuzSelectModal({
  btnLabel,
  btnIcon,
  callback,
  modalTitle,
  customer,
  advisor,
}: Properties) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const { formatTime } = useFormatters();
  const [documentIsLoading, setDocumentIsLoading] = useState<string[]>([]);

  const { currentData, isFetching } = useGetDocumentsQuery(
    {
      "X-Role-Id": getRoleId(),
      sort: ["createdAt:desc"],
      level: ALL,
      documentTypes: [DocumentTypeKeys.ZUZ],
      documentStates: [SIGNED],
      customerId: customer?.id,
      advisorId: advisor?.id,
    },
    {
      skip: !customer && !advisor,
      refetchOnMountOrArgChange: true,
    }
  );

  const [selectedRow, setSelectedRow] = useState<Document>();

  const selectDocument = (document: Document): void => {
    setSelectedRow(document);
  };

  const selectDocumentAndClose = (document: Document): void => {
    setSelectedRow(document);
    handleChange();
  };

  const handleChange = () => {
    setShowModal(false);
    if (selectedRow) {
      callback({
        note: selectedRow.displayName,
        file: selectedRow.file,
        isCcaFile: true,
        documentId: selectedRow?.id ?? "",
        id: undefined,
      });
      setSelectedRow(undefined);
    }
  };

  const handleCloseClick = () => {
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handlePreviewClick = (item: any) => {
    setDocumentIsLoading([...documentIsLoading, item.id]);

    openDocument(item.id)
      .catch(() => {
        toast.error(t("toast.document.error.pdfPreview"));
      })
      .finally(() => {
        setDocumentIsLoading(documentIsLoading.filter((id) => id !== item.id));
      });
  };

  return (
    <>
      <Button
        onClick={handleModalOpen}
        className="text-sm"
        icon={btnIcon}
        disabled={!customer || !advisor}
      >
        {btnLabel}
      </Button>
      {showModal ? (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-normal">
            <div className="relative my-6 mx-auto max-w-2xl">
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white text-gray-700 outline-none focus:outline-none">
                {/* header */}
                <div className="p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl inline-block font-semibold">
                    {modalTitle}
                  </h3>
                  <div className="float-right flex">
                    <button
                      type="button"
                      className="p-1 inline-flex ml-auto bg-transparent border-0 text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={handleCloseClick}
                    >
                      <span className="bg-transparent h-6 w-6 text-2xl outline-none focus:outline-none flex items-center">
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </button>
                  </div>
                </div>
                {/* body */}
                <div className="relative p-6 pt-2 flex-auto h-80 overflow-y-auto">
                  <table className="">
                    <thead>
                      <tr>
                        <th className="p-4 text-left">Vytvorené</th>
                        <th className="p-4 text-left">Aktualizované</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    {isFetching && !currentData?.items ? (
                      <TableBodyLoading cols={3} rows={3} />
                    ) : null}
                    <tbody>
                      {currentData?.items?.map((document) => (
                        <tr
                          className={
                            document === selectedRow
                              ? "cursor-pointer bg-green-200 hover:bg-green-300"
                              : "cursor-pointer bg-white"
                          }
                          key={document.id}
                          onClick={() => selectDocument(document)}
                          onDoubleClick={() => selectDocumentAndClose(document)}
                        >
                          <td>
                            <div>{formatTime(document.createdAt)}</div>
                          </td>
                          <td>
                            <div>{formatTime(document.updatedAt)}</div>
                          </td>
                          <td>
                            <div>
                              <Button
                                inProgress={documentIsLoading.includes(
                                  document.id ?? ""
                                )}
                                disabled={documentIsLoading.includes(
                                  document.id ?? ""
                                )}
                                onClick={() => handlePreviewClick(document)}
                                className="text-sm"
                                icon={faEye}
                              >
                                {document.displayName}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!isFetching && currentData?.count === 0 ? (
                    <Alert className="mt-4">
                      <span>{t("table.noData")}</span>
                    </Alert>
                  ) : (
                    ""
                  )}
                </div>
                {/* footer */}
                <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <div className="flex flex-row gap-4">
                    <Button
                      onClick={() => {
                        handleChange();
                      }}
                      disabled={!selectedRow}
                    >
                      <span>{t("button.select")}</span>
                    </Button>
                    <Button onClick={handleCloseClick}>
                      <span>{t("button.cancel")}</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </div>
      ) : null}
    </>
  );
}

ZuzSelectModal.defaultProps = {
  btnIcon: undefined,
  customer: undefined,
  advisor: undefined,
};
