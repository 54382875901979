import { Role } from "../api/permissionRule.generated";
import { RoleKey } from "../api/users.generated";
import RoleType from "./RoleType";
import SelectOption from "./SelectOption";

const RoleOptions: SelectOption<RoleKey>[] = [
  {
    name: "Centrála",
    key: RoleType.CENTRAL,
  },
  {
    name: "Systémový administrátor",
    key: RoleType.SYSTEM_ADMIN,
  },
  {
    name: "Asistent",
    key: RoleType.ASSISTANT,
  },
  {
    name: "Poradca",
    key: RoleType.ADVISOR,
  },
];

export const getRoleName = (key: string | undefined) => {
  console.log("key", key);
  const role = RoleOptions.find((r) => r.key === key);
  return role ? role.name : "";
};

export const getRole = (key: RoleKey): Role | undefined => {
  return RoleOptions.find((r) => r.key === key);
};

export const getRolesFromKeys = (keys: RoleKey[]): Role[] => {
  const roles: Role[] = [];
  keys.forEach((key) => {
    const role = RoleOptions.find((r) => r.key === key);
    if (role) {
      roles.push(role);
    }
  });
  return roles;
};

export default RoleOptions;
