import React, { useEffect, useState } from "react";

import {
  faCheckCircle,
  faTimesCircle,
  faCircle,
  faAddressCard,
  faPencil,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import {
  PermissionRuleRights,
  PermissionRuleRightsActionType,
} from "../../../api/permissionRule.generated";
import AddButton from "../../../components/buttons/AddButton";
import Card from "../../../components/card/Card";
import CardBody from "../../../components/card/CardBody";
import CardHeader from "../../../components/card/CardHeader";
import PermissionModal from "../../../components/modals/PermissionModal";
import PermissionObjectSelectOptions from "../../../enums/PermissionObjectSelectOptions";
import PermissionRuleActionTypeKey from "../../../enums/PermissionRuleActionTypeKey";

interface PermissionListProps {
  permissionList: PermissionRuleRights[];
  onUpdate?: (permissions: PermissionRuleRights[]) => void;
}

function PermissionList({ permissionList, onUpdate }: PermissionListProps) {
  const { t } = useTranslation();
  const [permission, setPermission] = React.useState<{
    permission_object_type: PermissionRuleRights | undefined;
    isEdit: boolean;
  }>({
    permission_object_type: undefined,
    isEdit: false,
  });

  const [prList, setPrList] = React.useState<Array<PermissionRuleRights>>([]);
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const editItem = (permissionRule: PermissionRuleRights) => {
    setPermission({
      permission_object_type: permissionRule,
      isEdit: true,
    });
    setShowPermissionModal(true);
  };

  const handlePermissionSave = (permissionRule: PermissionRuleRights) => {
    const prListCopy = [...prList];

    const index = prListCopy?.findIndex((pr) => {
      return (
        pr?.permission_object_type?.key ===
        permissionRule?.permission_object_type?.key
      );
    });

    if (index !== -1) {
      prListCopy?.splice(index, 1, permissionRule);
    }

    if (index === -1) {
      prListCopy?.push(permissionRule);
    }

    onUpdate?.(prListCopy as PermissionRuleRights[]);
    setShowPermissionModal(false);
    setPrList(prListCopy);
    setPermission({
      permission_object_type: undefined,
      isEdit: false,
    });
  };

  const availablePermissionObjectList = PermissionObjectSelectOptions.filter(
    (option) =>
      !prList
        ?.map((excludeOpt) => excludeOpt.permission_object_type?.key)
        .includes(option.key)
  );

  const newItem = () => {
    const newPermissionRule: PermissionRuleRights = {
      create: {
        key: PermissionRuleActionTypeKey.NONE,
      },
      read: {
        key: PermissionRuleActionTypeKey.NONE,
      },
      update: {
        key: PermissionRuleActionTypeKey.NONE,
      },
      delete: {
        key: PermissionRuleActionTypeKey.NONE,
      },
      permission_object_type: availablePermissionObjectList[0],
    };

    setShowPermissionModal(true);

    setPermission({
      permission_object_type: newPermissionRule,
      isEdit: false,
    });
  };

  const removeItem = (permissionRule: PermissionRuleRights) => {
    const prListCopy = [...prList];

    const index = prListCopy?.findIndex((pr) => {
      return (
        pr?.permission_object_type?.key ===
        permissionRule?.permission_object_type?.key
      );
    });

    if (index !== -1) {
      prListCopy?.splice(index, 1);
    }

    onUpdate?.(prListCopy as PermissionRuleRights[]);
    setPrList(prListCopy);
  };

  useEffect(() => {
    setPrList(permissionList);
  }, [permissionList]);

  return (
    <>
      <Card>
        <CardHeader
          icon={faAddressCard}
          label={t("app.rules-detail.card.permissionList.header") || ""}
        >
          <AddButton
            className="!py-1 !px-2 !text-sm"
            onClick={() => newItem()}
          />
        </CardHeader>
        <CardBody>
          <table>
            <thead>
              <tr>
                <td className="w-2/12 text-center">
                  <span>{t("app.table.role.permission.read")}</span>
                </td>
                <td className="w-2/12 text-center">
                  <span>{t("app.table.role.permission.create")}</span>
                </td>
                <td className="w-2/12 text-center">
                  <span>{t("app.table.role.permission.update")}</span>
                </td>
                <td className="w-2/12 text-center">
                  <span>{t("app.table.role.permission.delete")}</span>
                </td>
                <td className="w-4/12">
                  <span>{t("app.form.permissionObjectType")}</span>
                </td>
                <td />
              </tr>
            </thead>
            <tbody>
              {prList.length > 0 ? (
                prList?.map((permissionRuleItem) => (
                  <tr
                    key={`index-${permissionRuleItem?.permission_object_type?.key}`}
                  >
                    <td className="w-2/12 text-center">
                      <OperationStatusIcon
                        actionType={permissionRuleItem.read}
                      />
                    </td>
                    <td className="w-2/12 text-center">
                      <OperationStatusIcon
                        actionType={permissionRuleItem.create}
                      />
                    </td>
                    <td className="w-2/12 text-center">
                      <OperationStatusIcon
                        actionType={permissionRuleItem.update}
                      />
                    </td>
                    <td className="w-2/12 text-center">
                      <OperationStatusIcon
                        actionType={permissionRuleItem.delete}
                      />
                    </td>
                    <td className="w-3/12 text-spf-primary">
                      {permissionRuleItem.permission_object_type?.name}
                    </td>
                    <td className="w-1/12">
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faPencil}
                          className="text-xs hover:text-amber-600 cursor-pointer p-1"
                          onClick={() => editItem(permissionRuleItem)}
                        />
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="text-xs hover:text-amber-600 cursor-pointer p-1"
                          onClick={() => removeItem(permissionRuleItem)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <p className="text-center text-sm text-spf-gray">
                      <span>{t("table.noData")}</span>
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>

      <PermissionModal
        showModal={showPermissionModal}
        isEdit={permission?.isEdit}
        permission={
          permission?.isEdit ? permission.permission_object_type : undefined
        }
        onSave={handlePermissionSave}
        onClose={() => {
          setShowPermissionModal(false);
          setPermission({
            permission_object_type: undefined,
            isEdit: false,
          });
        }}
        availablePermissionObjectList={availablePermissionObjectList}
      />
    </>
  );
}

function OperationStatusIcon({
  actionType,
}: {
  actionType: PermissionRuleRightsActionType | undefined;
}) {
  let icon;
  let color;
  switch (actionType?.key) {
    case PermissionRuleActionTypeKey.ADD:
      icon = faCheckCircle;
      color = "text-spf-green";
      break;
    case PermissionRuleActionTypeKey.REMOVE:
      icon = faTimesCircle;
      color = "text-spf-red";
      break;
    default:
      icon = faCircle;
      color = "text-spf-gray";
  }

  return <FontAwesomeIcon icon={icon} className={color} />;
}

PermissionList.defaultProps = {
  onUpdate: () => null,
};

export default PermissionList;
