/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";

import {
  faUserAlt,
  faUser,
  faLock,
  faMapMarkerAlt,
  faHistory,
  faUsers,
  faSyncAlt,
  faIdCard,
  faUserCircle,
  faTrashAlt,
  faHandshakeAlt,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import ReactRouterPrompt from "react-router-prompt";
import { toast } from "react-toastify";

import { useRemoveAdvisorMutation } from "../../api/advisors.generated";
import { ValidationError } from "../../api/auth.generated";
import {
  useGetCustomerByIdQuery,
  useUpdateCustomerMutation,
  useInitiateCustomerIdentProcessMutation,
  CustomerUpdateRequest,
} from "../../api/customers.generated";
import { Customer } from "../../api/documents.generated";
import CustomerValidIdentificationBadge from "../../components/badges/CustomerValidIdentificationBadge";
import OriginBadge from "../../components/badges/OriginBadge";
import BackButton from "../../components/buttons/BackButton";
import Button from "../../components/buttons/Button";
import CancelButton from "../../components/buttons/CancelButton";
import UpdateButton from "../../components/buttons/UpdateButton";
import Card from "../../components/card/Card";
import CardBody from "../../components/card/CardBody";
import CardFooter from "../../components/card/CardFooter";
import CardHeader from "../../components/card/CardHeader";
import SelectCustomerType from "../../components/inputs/SelectCustomerType";
import SelectGender from "../../components/inputs/SelectGender";
import ValidatedCheckbox from "../../components/inputs/ValidatedCheckbox";
import ValidatedInput from "../../components/inputs/ValidatedInput";
import ValidatedInputDate from "../../components/inputs/ValidatedInputDate";
import ValidatedInputPhone from "../../components/inputs/ValidatedInputPhone";
import ConfirmModal from "../../components/modals/ConfirmModal";
import CustomerFormReturnToDocumentModal from "../../components/modals/CustomerFormReturnToDocumentModal";
import FormIsDirtyModal from "../../components/modals/FormIsDirtyModal";
import PageHeader from "../../components/page/PageHeader";
import CustomerType, { CT_FO } from "../../enums/CustomerType";
import Gender from "../../enums/Gender";
import Roles from "../../enums/Roles";
import useClientUtils from "../../hooks/clientUtils";
import { validatePhoneNumber } from "../../hooks/customers";
import useHandleApiError from "../../hooks/error";
import useFormatters from "../../hooks/formatter";
import useHelpers from "../../hooks/helper";
import { useCustomerIdentificationValidator } from "../../hooks/identification";
import { getRoleId } from "../../hooks/role";
import { makeRoute, parseUrl } from "../../hooks/route";
import { useAppSelector } from "../../hooks/store";
import { fixTimezoneOffset } from "../../hooks/timezone";
import { authSelector } from "../auth/authSlice";
import Error403View from "../error/Error403View";
import Error404View from "../error/Error404View";
import PageLoader from "../loader/PageLoader";
import AllRoutes from "../route/Route";

function CustomerEditView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { testEmail } = useHelpers();

  const { currentRole } = useAppSelector(authSelector);
  const { isIdentificationNumberSameAsBirthDate } = useClientUtils();

  const { apiDate, formatTime, formatPin, formatEmail, formatApiError } =
    useFormatters();

  const handleApiError = useHandleApiError();

  const [identificationButtonDisabled, setIdentificationButtonDisabled] =
    useState(false);
  const [blockNavigation, setBlockNavigation] = useState(false);
  const [showIdentificationModal, setShowIdentificationModal] = useState(false);
  const main = window.document.querySelector("main");

  useEffect(() => {
    const parsedQueryParams = parseUrl(location.search);
    if (
      Object.keys(parsedQueryParams).includes("document") &&
      Object.keys(parsedQueryParams).includes("customerType") &&
      main
    ) {
      main.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  const { currentData, isFetching, isError, error, refetch } =
    useGetCustomerByIdQuery(
      {
        "X-Role-Id": getRoleId(),
        id: id ?? "",
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    if (currentData) {
      setCustomerData(currentData);
    }
  }, [currentData]);

  const [removeAdvisorFromCustomer] = useRemoveAdvisorMutation();

  const [customerUpdate, customerUpdateState] = useUpdateCustomerMutation();
  const [initiateCustomerIdentification] =
    useInitiateCustomerIdentProcessMutation();

  const [customerData, setCustomerData] = useState(currentData);

  const [formErrors, setFormErrors] = useState<ValidationError[]>([]);

  const [showReturnToDocumentModal, setShowReturnToDocumentModal] =
    useState(false);
  const [showPINModal, setShowPINModal] = useState(false);

  const [showFormIsDirtyModal, setShowFormIsDirtyModal] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const validator = useCustomerIdentificationValidator();

  const getDirtyFields = (formData: Customer) => {
    const dirtyFields: string[] = [];
    for (const [key, value] of Object.entries(formData)) {
      const isDirty = !currentData
        ? false
        : value !== currentData[key as keyof Customer];
      if (isDirty) {
        dirtyFields.push(key);
      }
    }

    return dirtyFields;
  };

  useEffect(() => {
    if (customerData) {
      const dirtyFields = getDirtyFields(customerData ?? {});
      if (dirtyFields.length > 0) {
        setBlockNavigation(true);
      } else {
        setBlockNavigation(false);
      }
    }
  }, [customerData]);

  const handlePhoneInputChange = (value: string) => {
    let newErrors = [...formErrors];
    const key = newErrors.findIndex((item) => item.field === "phone_number");
    newErrors = newErrors.filter((item, i) => i !== key);
    const validatePhoneNumberRes = validatePhoneNumber(value);

    setCustomerData({
      ...customerData,
      phoneNumber: validatePhoneNumberRes.field,
    });
    if (validatePhoneNumberRes.message) {
      newErrors.push({
        field: "phone_number",
        message: validatePhoneNumberRes.message,
      });
      setFormErrors(newErrors);
    }
    setFormErrors(newErrors);
  };

  const getNewObject = (): CustomerUpdateRequest => {
    return {
      pin: customerData?.pin ?? currentData?.pin ?? "",
      typeKey: currentData?.type?.key ?? undefined,
      email: customerData?.email ?? currentData?.email ?? "",
      firstname: customerData?.firstname ?? currentData?.firstname ?? "",
      lastname: customerData?.lastname ?? currentData?.lastname ?? "",
      dateOfBirth: apiDate(
        customerData?.dateOfBirth ?? currentData?.dateOfBirth ?? ""
      ),
      authorizedPersonPosition:
        customerData?.authorizedPersonPosition ??
        currentData?.authorizedPersonPosition ??
        "",
      ico: customerData?.ico ?? currentData?.ico ?? "",
      businessName:
        customerData?.businessName ?? currentData?.businessName ?? "",
      cop: customerData?.cop ?? currentData?.cop ?? "",
      contractAgreement:
        customerData?.contractAgreement ??
        currentData?.contractAgreement ??
        false,
      foreignClient:
        customerData?.foreignClient ?? currentData?.foreignClient ?? false,
      contractSignedAt:
        customerData?.contractSignedAt ?? currentData?.contractSignedAt ?? "",
      passportNumber:
        customerData?.passportNumber ?? currentData?.passportNumber ?? "",
      opValidUntil: apiDate(
        customerData?.opValidUntil ?? currentData?.opValidUntil ?? ""
      ),
      pasValidUntil: apiDate(
        customerData?.pasValidUntil ?? currentData?.pasValidUntil ?? ""
      ),
      otherIDCardValidUntil: apiDate(
        customerData?.otherIDCardValidUntil ??
          currentData?.otherIDCardValidUntil ??
          ""
      ),
      otherIDCardNumber:
        customerData?.otherIDCardNumber ?? currentData?.otherIDCardNumber,
      otherIDCardType:
        customerData?.otherIDCardType ?? currentData?.otherIDCardType ?? "",
      citizenship: customerData?.citizenship ?? currentData?.citizenship ?? "",
      profession: customerData?.profession ?? currentData?.profession ?? "",
      phoneNumber: customerData?.phoneNumber ?? currentData?.phoneNumber ?? "",
      title: customerData?.title ?? currentData?.title ?? "",
      genderKey:
        customerData?.gender?.key ?? currentData?.gender?.key ?? undefined,
      dateOfConsent:
        customerData?.dateOfConsent ?? currentData?.dateOfConsent ?? "",
      note: customerData?.note ?? currentData?.note ?? "",
      personalMarketingInformation:
        customerData?.personalMarketingInformation ??
        currentData?.personalMarketingInformation ??
        false,
      permanentAddress: {
        city:
          customerData?.permanentAddress?.city ??
          currentData?.permanentAddress?.city ??
          "",
        countryKey:
          customerData?.permanentAddress?.country?.key ??
          currentData?.permanentAddress?.country?.key ??
          "sk",
        street:
          customerData?.permanentAddress?.street ??
          currentData?.permanentAddress?.street ??
          "",
        zip:
          customerData?.permanentAddress?.zip ??
          currentData?.permanentAddress?.zip ??
          "",
      },
      correspondenceAddress: {
        city:
          customerData?.correspondenceAddress?.city ??
          currentData?.correspondenceAddress?.city ??
          "",
        countryKey:
          customerData?.correspondenceAddress?.country?.key ??
          currentData?.correspondenceAddress?.country?.key ??
          "sk",
        street:
          customerData?.correspondenceAddress?.street ??
          currentData?.correspondenceAddress?.street ??
          "",
        zip:
          customerData?.correspondenceAddress?.zip ??
          currentData?.correspondenceAddress?.zip ??
          "",
      },
      businessAddress: {
        city:
          customerData?.businessAddress?.city ??
          currentData?.businessAddress?.city ??
          "",
        countryKey:
          customerData?.businessAddress?.country?.key ??
          currentData?.businessAddress?.country?.key ??
          "sk",
        street:
          customerData?.businessAddress?.street ??
          currentData?.businessAddress?.street ??
          "",
        zip:
          customerData?.businessAddress?.zip ??
          currentData?.businessAddress?.zip ??
          "",
      },
    };
  };

  const prevalidateAndUpdate = () => {
    setBlockNavigation(false);
    const newObj = getNewObject();

    if (!validate(newObj)) {
      return;
    }

    if (
      !isIdentificationNumberSameAsBirthDate(
        newObj?.pin ?? "",
        newObj?.dateOfBirth ?? "",
        newObj?.genderKey ?? ""
      )
    ) {
      setShowPINModal(true);
      return;
    }

    doUpdateCustomer(newObj);
  };

  const doUpdateCustomer = async (newObj: CustomerUpdateRequest) => {
    setBlockNavigation(false);
    setIsUpdating(true);

    if (id) {
      customerUpdate({
        "X-Role-Id": getRoleId(),
        id,
        customerUpdateRequest: newObj,
      })
        .unwrap()
        .then((response) => {
          const parsedQueryParams = parseUrl(location.search);
          if (
            Object.keys(parsedQueryParams).includes("document") &&
            Object.keys(parsedQueryParams).includes("customerType")
          ) {
            setShowReturnToDocumentModal(true);
            return false;
          }
          refetch();
          toast.success(t("app.customer.edit.success"));
          setIsUpdating(false);
          return navigate(makeRoute(AllRoutes.CUSTOMERS_LIST));
        })
        .catch((errorResponse) => {
          handleApiError(errorResponse, {
            on400: (data) => {
              setFormErrors(data);
              toast.error(formatApiError(errorResponse), {
                closeOnClick: true,
              });
            },
            onFinally: () => {
              setBlockNavigation(true);
              setIsUpdating(false);
            },
          });
          return false;
        });
    }
  };

  const validate = (form: CustomerUpdateRequest) => {
    const newFormErrors = [];

    // validate
    if (form.firstname === "") {
      newFormErrors.push({
        field: "firstname",
        message: t("app.customerForm.firstname.required"),
      });
    }

    if (form.lastname === "") {
      newFormErrors.push({
        field: "lastname",
        message: t("app.customerForm.lastname.required"),
      });
    }

    if (form.email === "") {
      newFormErrors.push({
        field: "email",
        message: t("app.customerForm.email.required"),
      });
    }

    if (form.email !== "" && form.email && !testEmail(form.email)) {
      newFormErrors.push({
        field: "email",
        message: t("app.customerForm.email.formatError"),
      });
    }

    setFormErrors(newFormErrors);

    return !(newFormErrors.length > 0);
  };

  const startIdentification = () => {
    setIdentificationButtonDisabled(true);
    initiateCustomerIdentification({ "X-Role-Id": getRoleId(), id: id ?? "" })
      .catch((err) => {
        toast.error(t("app.page.error.defaultMessage") + err);
        setIdentificationButtonDisabled(false);
      })
      .then((response: any) => {
        if (response.error) {
          setFormErrors(response.error.data.data);
          return toast.warning(
            t("app.customer.edit.identification.start.failed"),
            {
              closeOnClick: true,
            }
          );
        }
        return toast.success(
          t("app.customer.edit.identification.start.success")
        );
      });
  };

  const removeAdvisorRelationship = (advisorId: string) => {
    removeAdvisorFromCustomer({
      "X-Role-Id": getRoleId(),
      advisorId,
      id: id ?? "",
    })
      .catch((err) => {
        toast.error(t("app.page.error.defaultMessage") + err);
      })
      .then((response: any) => {
        if (response.error) {
          setFormErrors(response.error.data.data);
          return toast.warning(t("app.customer.edit.advisor.remove.failed"), {
            closeOnClick: true,
          });
        }
        refetch();
        return toast.success(t("app.customer.edit.advisor.remove.success"));
      });
  };

  const getPersonalAdvisorName = () => {
    if (
      customerData?.personalAdvisor?.firstname &&
      customerData?.personalAdvisor?.lastname
    ) {
      return `${customerData?.personalAdvisor?.firstname} ${customerData?.personalAdvisor?.lastname}`;
    }

    if (
      currentData?.personalAdvisor?.firstname &&
      currentData?.personalAdvisor?.lastname
    ) {
      return `${currentData?.personalAdvisor?.firstname} ${currentData?.personalAdvisor?.lastname}`;
    }

    return "";
  };

  if (!isFetching && isError && error) {
    if ("data" in error && error.status === 403) {
      return <Error403View error={error} />;
    }

    return <Error404View />;
  }

  if (isFetching && !currentData) return <PageLoader />;

  return (
    <>
      <PageHeader
        icon={faUserAlt}
        label={
          currentData?.type?.key === CT_FO
            ? `${currentData?.lastname} ${currentData?.firstname}`
            : `${currentData?.businessName}`
        }
      >
        <div className="flex gap-2 rounded-full bg-slate-200">
          <CustomerValidIdentificationBadge
            id={currentData?.validIdentificationId}
            className="flex px-4 py2 items-center"
          />
        </div>
        <div className="px-4 py-2 flex gap-2 rounded-full bg-slate-200">
          <FontAwesomeIcon icon={faUserCircle} className="mt-1" />
          {currentData?.updatedBy
            ? `${currentData?.updatedBy?.displayName}`
            : currentData?.updatedByICM}
        </div>
        <div className="px-4 py-2 mr-8 flex gap-2 rounded-full bg-slate-200">
          <FontAwesomeIcon icon={faHistory} className="mt-1" />
          {formatTime(currentData?.updatedAt)} -{" "}
          <span>{t("app.form.unlimited")}</span>
        </div>
        <BackButton
          className="mr-2"
          onClick={() => {
            const dirtyFields = getDirtyFields(customerData ?? {});
            const parsedQueryParams = parseUrl(location.search);
            if (
              Object.keys(parsedQueryParams).includes("document") &&
              Object.keys(parsedQueryParams).includes("customerType")
            ) {
              setShowReturnToDocumentModal(true);
            } else if (dirtyFields.length > 0) {
              setShowFormIsDirtyModal(true);
            } else {
              navigate(AllRoutes.CUSTOMERS_LIST.path);
            }
          }}
        />
        <Button
          disabled={identificationButtonDisabled}
          icon={faLock}
          onClick={() => {
            if (identificationButtonDisabled) {
              return;
            }
            if (customerData && validator.validateCustomer(customerData)) {
              setShowIdentificationModal(true);
            }
          }}
        >
          <span>{t("button.identificate")}</span>
        </Button>
        <UpdateButton
          onClick={() => prevalidateAndUpdate()}
          dataTestId="/admin/customers/edit/btn-save"
          disabled={isUpdating}
        />
      </PageHeader>

      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              icon={faUser}
              label={t("app.customer.edit.card.customer.header") || ""}
            >
              {isFetching ? (
                <FontAwesomeIcon className="mr-2" icon={faSyncAlt} spin />
              ) : (
                ""
              )}
              <OriginBadge origin={currentData?.origin} />
            </CardHeader>
            <CardBody>
              <div className="py-1 grid grid-cols-5 gap-4">
                <ValidatedInput
                  label={t("app.form.title")}
                  field="title"
                  type="text"
                  compareValue={currentData?.icmDiff?.title}
                  value={customerData?.title ?? currentData?.title ?? ""}
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      title: e.currentTarget.value,
                    })
                  }
                />
                <div className="col-span-2">
                  <ValidatedInput
                    label={t("app.form.firstname")}
                    field="firstname"
                    type="text"
                    compareValue={currentData?.icmDiff?.firstname}
                    value={
                      customerData?.firstname ?? currentData?.firstname ?? ""
                    }
                    errors={formErrors}
                    required
                    callback={(e) =>
                      setCustomerData({
                        ...customerData,
                        firstname: e.currentTarget.value,
                      })
                    }
                  />
                </div>
                <div className="col-span-2">
                  <ValidatedInput
                    className="col-span-2"
                    label={t("app.form.lastname")}
                    field="lastname"
                    type="text"
                    compareValue={currentData?.icmDiff?.lastname}
                    value={
                      customerData?.lastname ?? currentData?.lastname ?? ""
                    }
                    errors={formErrors}
                    required
                    callback={(e) =>
                      setCustomerData({
                        ...customerData,
                        lastname: e.currentTarget.value || "",
                      })
                    }
                  />
                </div>
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInputPhone
                  label={t("app.form.phone")}
                  field="phone_number"
                  compareValue={currentData?.icmDiff?.phoneNumber}
                  value={
                    customerData?.phoneNumber ?? currentData?.phoneNumber ?? ""
                  }
                  errors={formErrors}
                  required={false}
                  placeholder="+421XXXXXXXXX"
                  callback={(e) =>
                    handlePhoneInputChange(e.currentTarget.value)
                  }
                />
                <ValidatedInput
                  label={t("app.form.email")}
                  field="email"
                  type="text"
                  compareValue={currentData?.icmDiff?.email}
                  value={customerData?.email ?? currentData?.email ?? ""}
                  errors={formErrors}
                  required
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      email: formatEmail(e.currentTarget.value) || "",
                    })
                  }
                />
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInputDate
                  label={t("app.form.dateOfBirth")}
                  field="dateOfBirth"
                  compareValue={
                    currentData?.icmDiff?.dateOfBirth
                      ? new Date(currentData?.icmDiff?.dateOfBirth)
                      : undefined
                  }
                  value={
                    customerData?.dateOfBirth
                      ? new Date(customerData?.dateOfBirth)
                      : currentData?.dateOfBirth
                      ? new Date(currentData?.dateOfBirth)
                      : undefined
                  }
                  errors={formErrors}
                  required={false}
                  callback={(date: Date) =>
                    setCustomerData({
                      ...customerData,
                      dateOfBirth: fixTimezoneOffset(date).toISOString(),
                    })
                  }
                />
                <ValidatedInput
                  label={t("app.form.pin")}
                  field="pin"
                  type="text"
                  compareValue={currentData?.icmDiff?.pin}
                  value={customerData?.pin ?? currentData?.pin ?? ""}
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      pin: formatPin(e.currentTarget.value),
                    })
                  }
                />
              </div>
              <div className="py-1 grid grid-cols-2 gap-4">
                <SelectCustomerType
                  selectedOption={currentData?.type?.key}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      type:
                        CustomerType.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? CustomerType.pop(),
                    });
                  }}
                  disabled
                />
                <ValidatedInput
                  label={t("app.form.citizenship")}
                  field="citizenship"
                  type="text"
                  value={
                    customerData?.citizenship ?? currentData?.citizenship ?? ""
                  }
                  required={false}
                  errors={formErrors}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      citizenship: e.currentTarget.value,
                    })
                  }
                />
                <SelectGender
                  compareValue={currentData?.icmDiff?.genderKey}
                  selectedOption={customerData?.gender}
                  onChange={(e) => {
                    setCustomerData({
                      ...customerData,
                      gender:
                        Gender.filter(
                          (item) => item.key === e.currentTarget.value
                        ).pop() ?? Gender.pop(),
                    });
                  }}
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faIdCard}
              label={t("card.documents.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.cop")}
                  field="cop"
                  type="text"
                  compareValue={currentData?.icmDiff?.cop}
                  value={customerData?.cop ?? currentData?.cop ?? ""}
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      cop: e.currentTarget.value,
                    })
                  }
                />
                <ValidatedInputDate
                  label={t("app.form.opValidUntil")}
                  field="opValidUntil"
                  value={
                    customerData?.opValidUntil
                      ? new Date(customerData.opValidUntil)
                      : currentData?.opValidUntil
                      ? new Date(currentData.opValidUntil)
                      : undefined
                  }
                  errors={formErrors}
                  required={false}
                  callback={(date: Date) =>
                    setCustomerData({
                      ...customerData,
                      opValidUntil: date.toISOString(),
                    })
                  }
                />
                <ValidatedInput
                  label={t("app.form.passportNumber")}
                  field="passportNumber"
                  type="text"
                  compareValue={currentData?.icmDiff?.passportNumber}
                  value={
                    customerData?.passportNumber ??
                    currentData?.passportNumber ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      passportNumber: e.currentTarget.value,
                    })
                  }
                />
                <ValidatedInputDate
                  label={t("app.form.pasValidUntil")}
                  field="pasValidUntil"
                  required={false}
                  value={
                    customerData?.pasValidUntil
                      ? new Date(customerData.pasValidUntil)
                      : currentData?.pasValidUntil
                      ? new Date(currentData.pasValidUntil)
                      : undefined
                  }
                  errors={formErrors}
                  callback={(date: Date) =>
                    setCustomerData({
                      ...customerData,
                      pasValidUntil: date.toISOString(),
                    })
                  }
                />
              </div>
              <div className="py-1 grid grid-cols-3 gap-4">
                <ValidatedInput
                  label={t("app.form.otherIdCardType")}
                  field="otherIdCardType"
                  type="text"
                  value={
                    customerData?.otherIDCardType ??
                    currentData?.otherIDCardType ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      otherIDCardType: e.currentTarget.value,
                    })
                  }
                />
                <ValidatedInput
                  label={t("app.form.otherIdCardNumber")}
                  field="cop"
                  type="text"
                  compareValue={currentData?.icmDiff?.otherIDCardNumber}
                  value={
                    customerData?.otherIDCardNumber ??
                    currentData?.otherIDCardNumber ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      otherIDCardNumber: e.currentTarget.value,
                    })
                  }
                />
                <ValidatedInputDate
                  label={t("app.form.otherIdCardValidUntil")}
                  field="otherIdCardValidUntil"
                  value={
                    customerData?.otherIDCardValidUntil
                      ? new Date(customerData.otherIDCardValidUntil)
                      : currentData?.otherIDCardValidUntil
                      ? new Date(currentData.otherIDCardValidUntil)
                      : undefined
                  }
                  errors={formErrors}
                  required={false}
                  callback={(date: Date) =>
                    setCustomerData({
                      ...customerData,
                      otherIDCardValidUntil: date.toISOString(),
                    })
                  }
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader icon={faUsers} label={t("card.firm.header") || ""} />
            <CardBody>
              <ValidatedInput
                label={t("app.form.businessName")}
                field="businessName"
                type="text"
                compareValue={currentData?.icmDiff?.businessName}
                value={
                  customerData?.businessName ?? currentData?.businessName ?? ""
                }
                errors={formErrors}
                required={false}
                callback={(e) =>
                  setCustomerData({
                    ...customerData,
                    businessName: e.currentTarget.value,
                  })
                }
              />
              <ValidatedInput
                label={t("app.form.ico")}
                field="ico"
                type="text"
                compareValue={currentData?.icmDiff?.ico}
                value={customerData?.ico ?? currentData?.ico ?? ""}
                errors={formErrors}
                required={false}
                callback={(e) =>
                  setCustomerData({
                    ...customerData,
                    ico: e.currentTarget.value,
                  })
                }
              />
            </CardBody>
          </Card>
          <Card>
            <CardHeader
              icon={faHandshakeAlt}
              label={t("card.advisorsAndCollaboration.header") || ""}
            />
            <CardBody>
              <div className="grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.personalAdvisor")}
                  field="advisor"
                  type="text"
                  value={getPersonalAdvisorName()}
                  errors={formErrors}
                  disabled
                  required={false}
                  callback={(e) => null}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <ValidatedCheckbox
                    className="mb-0"
                    label={t("app.form.contractAgreementSignedOn")}
                    field="contractAgreement"
                    value={
                      customerData?.contractAgreement ??
                      currentData?.contractAgreement
                    }
                    errors={formErrors}
                    required={false}
                    disabled
                    callback={(e) =>
                      setCustomerData({
                        ...customerData,
                        contractAgreement: e.currentTarget.checked,
                      })
                    }
                  />
                </div>
                <div className="flex justify-end">
                  <ValidatedInputDate
                    label=""
                    className="w-[220px]"
                    field="contractSignedAt"
                    value={
                      customerData?.contractSignedAt
                        ? new Date(customerData.contractSignedAt)
                        : currentData?.contractSignedAt
                        ? new Date(currentData.contractSignedAt)
                        : undefined
                    }
                    errors={formErrors}
                    required={false}
                    disabled
                    callback={(date: Date) => {
                      setCustomerData({
                        ...customerData,
                        contractSignedAt: date.toISOString(),
                      });
                    }}
                  />
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <div className="relative flex-auto h-72 overflow-y-auto">
                <table className="">
                  <thead>
                    <tr>
                      <th className="p-4 text-left">
                        <span>{t("table.header.advisor")}</span>
                      </th>
                      <th className="p-4 text-left">
                        <span>{t("table.header.customerOrigin")}</span>
                      </th>
                      <th className="w-10">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData?.advisors?.map((advisorData, index) => (
                      <tr key={advisorData?.advisor?.id}>
                        <td className="w-1/2 text-spf-primary">
                          {advisorData?.advisor?.firstname}{" "}
                          {advisorData?.advisor?.lastname}
                        </td>
                        <td className="w-1/2 text-spf-primary">
                          {advisorData?.origin?.name ?? ""}
                        </td>
                        <td className="w-10">
                          {advisorData?.origin?.key === "aco-portal" &&
                          (Roles.CENTRAL === currentRole?.role?.key ||
                            Roles.SYSTEM_ADMIN === currentRole?.role?.key) ? (
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              className="hover:text-red-700 cursor-pointer"
                              onClick={() =>
                                removeAdvisorRelationship(
                                  advisorData?.advisor?.id ?? ""
                                )
                              }
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))}

                    {currentData?.advisors?.length === 0 ? (
                      <tr>
                        <td colSpan={2}>
                          <p className="text-center text-gray-500 text-sm">
                            <span>{t("table.noData")}</span>
                          </p>
                        </td>
                        <td className="w-10">&nbsp;</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </CardFooter>
          </Card>
        </div>

        <div className="flex flex-col gap-4">
          <Card>
            <CardHeader
              icon={faLock}
              label={t("card.permanentAddress.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.street")}
                  field="street"
                  type="text"
                  compareValue={currentData?.icmDiff?.permanentAddress?.street}
                  value={
                    customerData?.permanentAddress?.street ??
                    currentData?.permanentAddress?.street ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        permanentAddress: {
                          ...customerData?.permanentAddress,
                          street: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />
                <ValidatedInput
                  label={t("app.form.city")}
                  field="city"
                  type="text"
                  compareValue={currentData?.icmDiff?.permanentAddress?.city}
                  value={
                    customerData?.permanentAddress?.city ??
                    currentData?.permanentAddress?.city ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        permanentAddress: {
                          ...customerData?.permanentAddress,
                          city: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.zip")}
                  field="zip"
                  type="text"
                  compareValue={currentData?.icmDiff?.permanentAddress?.zip}
                  value={
                    customerData?.permanentAddress?.zip ??
                    currentData?.permanentAddress?.zip ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        permanentAddress: {
                          ...customerData?.permanentAddress,
                          zip: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faMapMarkerAlt}
              label={t("card.correspondenceAddress.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.street")}
                  field="street"
                  type="text"
                  required={false}
                  compareValue={
                    currentData?.icmDiff?.correspondenceAddress?.street
                  }
                  value={
                    customerData?.correspondenceAddress?.street ??
                    currentData?.correspondenceAddress?.street ??
                    ""
                  }
                  errors={formErrors}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        correspondenceAddress: {
                          ...customerData?.correspondenceAddress,
                          street: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />

                <ValidatedInput
                  label={t("app.form.city")}
                  field="city"
                  type="text"
                  compareValue={
                    currentData?.icmDiff?.correspondenceAddress?.city
                  }
                  value={
                    customerData?.correspondenceAddress?.city ??
                    currentData?.correspondenceAddress?.city ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        correspondenceAddress: {
                          ...customerData?.correspondenceAddress,
                          city: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.zip")}
                  field="zip"
                  type="text"
                  compareValue={
                    currentData?.icmDiff?.correspondenceAddress?.zip
                  }
                  value={
                    customerData?.correspondenceAddress?.zip ??
                    currentData?.correspondenceAddress?.zip ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        correspondenceAddress: {
                          ...customerData?.correspondenceAddress,
                          zip: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faMapMarkerAlt}
              label={t("card.businessAddress.header") || ""}
            />
            <CardBody>
              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.street")}
                  field="street"
                  type="text"
                  compareValue={currentData?.icmDiff?.businessAddress?.street}
                  value={
                    customerData?.businessAddress?.street ??
                    currentData?.businessAddress?.street ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        businessAddress: {
                          ...customerData?.businessAddress,
                          street: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />

                <ValidatedInput
                  label={t("app.form.city")}
                  field="city"
                  type="text"
                  compareValue={currentData?.icmDiff?.businessAddress?.city}
                  value={
                    customerData?.businessAddress?.city ??
                    currentData?.businessAddress?.city ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        businessAddress: {
                          ...customerData?.businessAddress,
                          city: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />
              </div>

              <div className="py-1 grid grid-cols-2 gap-4">
                <ValidatedInput
                  label={t("app.form.zip")}
                  field="zip"
                  type="text"
                  compareValue={currentData?.icmDiff?.businessAddress?.zip}
                  value={
                    customerData?.businessAddress?.zip ??
                    currentData?.businessAddress?.zip ??
                    ""
                  }
                  errors={formErrors}
                  required={false}
                  callback={(e) =>
                    setCustomerData({
                      ...customerData,
                      ...{
                        businessAddress: {
                          ...customerData?.businessAddress,
                          zip: e.currentTarget.value,
                        },
                      },
                    })
                  }
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader
              icon={faFileContract}
              label={t("card.other.header") || ""}
            />
            <CardBody>
              <div className="grid grid-cols-2 gap-4">
                <span className="mb-3 text-sm text-gray-500 flex items-center">
                  {t("app.form.lastIdentification")}
                </span>
                <div className="flex justify-end">
                  <ValidatedInputDate
                    label=""
                    field="lastIDGuardIdentification"
                    className="w-[220px]"
                    value={
                      currentData?.lastIDGuardIdentification
                        ? new Date(currentData?.lastIDGuardIdentification)
                        : undefined
                    }
                    errors={formErrors}
                    required={false}
                    disabled
                    callback={(e) => {
                      console.log(e);
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <ValidatedCheckbox
                    label={t(
                      "app.form.personalMarketingInformationGeneratedOn"
                    )}
                    field="personalMarketingInformation"
                    value={
                      customerData?.personalMarketingInformation ??
                      currentData?.personalMarketingInformation
                    }
                    errors={formErrors}
                    required={false}
                    callback={(e) =>
                      setCustomerData({
                        ...customerData,
                        personalMarketingInformation: e.currentTarget.checked,
                      })
                    }
                    disabled
                  />
                </div>
                <div className="flex justify-end">
                  <ValidatedInputDate
                    className="w-[220px]"
                    label=""
                    field="dateOfConsent"
                    value={
                      customerData?.dateOfConsent
                        ? new Date(customerData.dateOfConsent)
                        : currentData?.dateOfConsent
                        ? new Date(currentData.dateOfConsent)
                        : undefined
                    }
                    disabled
                    errors={formErrors}
                    required={false}
                    callback={(date: Date) =>
                      setCustomerData({
                        ...customerData,
                        dateOfConsent: date.toISOString(),
                      })
                    }
                  />
                </div>
              </div>
              <ValidatedCheckbox
                label={t("app.form.foreignClient")}
                field="foreignClient"
                compareValue={currentData?.icmDiff?.foreignClient}
                value={
                  customerData?.foreignClient ?? currentData?.foreignClient
                }
                errors={formErrors}
                callback={(e) =>
                  setCustomerData({
                    ...customerData,
                    foreignClient: e.currentTarget.checked,
                  })
                }
              />
              <ValidatedInput
                label={t("app.form.profession")}
                field="profession"
                type="text"
                value={
                  customerData?.profession ?? currentData?.profession ?? ""
                }
                errors={formErrors}
                required={false}
                callback={(e) =>
                  setCustomerData({
                    ...customerData,
                    profession: e.currentTarget.value,
                  })
                }
              />
              <ValidatedInput
                label={t("app.form.authorizedPersonPosition")}
                field="authorizedPersonPosition"
                type="text"
                compareValue={currentData?.icmDiff?.authorizedPersonPosition}
                value={
                  customerData?.authorizedPersonPosition ??
                  currentData?.authorizedPersonPosition ??
                  ""
                }
                errors={formErrors}
                required={false}
                callback={(e) =>
                  setCustomerData({
                    ...customerData,
                    authorizedPersonPosition: e.currentTarget.value,
                  })
                }
              />
              <ValidatedInput
                label={t("app.form.note")}
                field="note"
                type="text"
                compareValue={currentData?.icmDiff?.note}
                value={customerData?.note ?? currentData?.note ?? ""}
                errors={formErrors}
                required={false}
                callback={(e) =>
                  setCustomerData({
                    ...customerData,
                    note: e.currentTarget.value,
                  })
                }
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <CustomerFormReturnToDocumentModal
        showModal={showReturnToDocumentModal}
        setShowModal={setShowReturnToDocumentModal}
        customerId={id ?? ""}
      />
      <ConfirmModal
        showModal={showPINModal}
        setShowModal={setShowPINModal}
        text={t("modal.dateOfBirthNotMatch")}
        confirmBtnText={t("button.save")}
        noBtnText={t("button.back")}
        actionConfirm={() => {
          doUpdateCustomer(getNewObject());
          setShowPINModal((prev) => !prev);
        }}
        actionInProgress={customerUpdateState.isLoading}
        actionNo={() => setShowPINModal(false)}
        showX
      />
      <FormIsDirtyModal
        show={showFormIsDirtyModal}
        setShow={setShowFormIsDirtyModal}
        callback={() => {
          navigate(AllRoutes.CUSTOMERS_LIST.path);
        }}
      />
      <ConfirmModal
        showModal={showIdentificationModal}
        setShowModal={setShowIdentificationModal}
        text={t("modal.identificationConfirm")}
        confirmBtnText={t("button.yes")}
        noBtnText={t("button.cancel")}
        actionConfirm={() => {
          startIdentification();
          setShowIdentificationModal(false);
        }}
        actionInProgress={customerUpdateState.isLoading}
        actionNo={() => setShowIdentificationModal(false)}
        showX
      />
      <ReactRouterPrompt when={blockNavigation}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <>
              <div className="opacity-25 fixed inset-0 z-40 bg-black" />
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto mx-auto max-w-3xl">
                  {/* content */}
                  <div className="rounded-lg shadow-xl relative flex flex-col bg-white w-96">
                    {/* header */}
                    <div className="flex flex-cols px-4 py-4" />
                    {/* body */}
                    <div className="px-4 py-4 my-4 text-blueGray-500">
                      <span>{t("formIsDirtyModal.question")}</span>
                    </div>
                    <div className="border-gray-300 text-center">
                      <div className="px-4 py-4">
                        <Button className="mr-1" onClick={onConfirm}>
                          <span>{t("button.yes")}</span>
                        </Button>
                        <CancelButton onClick={onCancel} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </ReactRouterPrompt>
    </>
  );
}

export default CustomerEditView;
