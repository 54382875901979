import React from "react";

import { PermissionRuleRightsActionTypeKey } from "../../api/permissionRule.generated";
import PermissionRuleActionTypeKey from "../../enums/PermissionRuleActionTypeKey";

interface ColoredCharactersProps {
  create?: PermissionRuleRightsActionTypeKey;
  read?: PermissionRuleRightsActionTypeKey;
  update?: PermissionRuleRightsActionTypeKey;
  delete?: PermissionRuleRightsActionTypeKey;
}

export function ColoredCharacters(props: ColoredCharactersProps): JSX.Element {
  const { create: c, read: r, update: u, delete: d } = props;

  const getColorClass = (value?: PermissionRuleRightsActionTypeKey): string => {
    switch (value) {
      case PermissionRuleActionTypeKey.ADD:
        return "text-spf-forrest-green";
      case PermissionRuleActionTypeKey.REMOVE:
        return "text-spf-muted-red";
      case PermissionRuleActionTypeKey.NONE:
      default:
        return "text-spf-neutral-gray";
    }
  };

  return (
    <div className="flex space-x-1 justify-center">
      {c !== undefined && (
        <span className={`${getColorClass(c)} font-bold`}>C</span>
      )}
      {r !== undefined && (
        <span className={`${getColorClass(r)} font-bold`}>R</span>
      )}
      {u !== undefined && (
        <span className={`${getColorClass(u)} font-bold`}>U</span>
      )}
      {d !== undefined && (
        <span className={`${getColorClass(d)} font-bold`}>D</span>
      )}
    </div>
  );
}

ColoredCharacters.defaultProps = {
  create: PermissionRuleActionTypeKey.NONE,
  read: PermissionRuleActionTypeKey.NONE,
  update: PermissionRuleActionTypeKey.NONE,
  delete: PermissionRuleActionTypeKey.NONE,
};

export default ColoredCharacters;
