import { t } from "i18next";

import { Address } from "../api/customers.generated";
import { ValidationError } from "../api/me.generated";

const priceFormatter = new Intl.NumberFormat("sk-SK", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
});

const formatPrice = (number: string | undefined): string => {
  return priceFormatter.format(number ? parseFloat(number) : 0.0);
};

const formatPriceWithoutCurrency = (number: string | undefined): string => {
  return priceFormatter
    .format(number ? parseFloat(number) : 0.0)
    .replace("€", "");
};

const formatPin = (pin: string | undefined): string | undefined => {
  if (!pin) return undefined;
  return pin.replace(/\//g, "");
};

const formatEmail = (email: string | undefined): string | undefined => {
  if (!email) return email;

  // replace all whitespaces
  const e = email.replace(/\s/g, "");

  return e;
};

const dateFormatter = new Intl.DateTimeFormat("sk-SK", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const timeFormatter = new Intl.DateTimeFormat("sk-SK", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

const timeWithoutSecondsFormatter = new Intl.DateTimeFormat("sk-SK", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
});

const formatDate = (date: string | undefined): string => {
  return date ? dateFormatter.format(Date.parse(date ?? "")) : "";
};

export const inputDate = (date: string | undefined): string => {
  const dt = Date.parse(date ?? "");
  const y = new Intl.DateTimeFormat("en", { year: "numeric" }).format(dt);
  const m = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(dt);
  const d = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(dt);

  return `${y}-${m}-${d}`;
};

export const apiDate = (date: string | undefined): string => {
  const dt = date ? new Date(date) : undefined;
  return dt ? dt.toISOString() : "";
};

const formatTime = (time: string | undefined): string => {
  return time ? timeFormatter.format(Date.parse(time ?? "")) : "";
};

const formatTimeWithoutSeconds = (time: string | undefined): string => {
  return time ? timeWithoutSecondsFormatter.format(Date.parse(time ?? "")) : "";
};

const formatTableTime = (time: string | undefined): string => {
  // @see https://stackoverflow.com/a/50000889
  const localDateTime = new Date(time ?? "");
  return [
    localDateTime.toLocaleDateString("sk-SK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    localDateTime.toLocaleTimeString("sk-SK", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    }),
  ].join(" ");
};

export const formatOpenHours = (hours: number | undefined) => {
  let hoursStr = String(hours || 0);
  while (hoursStr.length < 4) {
    hoursStr = `0${hoursStr}`;
  }

  return `${hoursStr.slice(0, 2)}:${hoursStr.slice(2, 4)}`;
};

const openHoursToValue = (value: string) => {
  const val = value.replace(":", "").slice(0, 4);
  return Number(val);
};

const formatAddress = (address: Address | undefined) => {
  if (!address?.street) return "";
  const cityWithZip = [address?.zip, address?.city].join(" ");
  const addressArray = [address?.street, cityWithZip];
  return addressArray.join(", ");
};

const formatExternalLinkImportResult = (message: string) => {
  let newMessage = message;
  newMessage = newMessage.replace("Error:", t("importLinks.error"));
  newMessage = newMessage.replace(
    "doesn't exist",
    t("importLinks.doesntExist")
  );
  newMessage = newMessage.replace(
    "cannot parse uuid",
    t("importLinks.parseUuid")
  );
  newMessage = newMessage.replace(
    "advisor with email",
    t("importLinks.advisorWithEmail")
  );
  newMessage = newMessage.replace(
    "advisor with email",
    t("importLinks.advisorWithEmail")
  );
  newMessage = newMessage.replace(
    "cannot parse status",
    t("importLinks.cannotParseStatus")
  );
  newMessage = newMessage.replace(
    "couldn't find ExternalLink with id",
    t("importLinks.linkNotExists")
  );
  newMessage = newMessage.replace(
    "externalLink with id",
    t("importLinks.linkWithId")
  );
  newMessage = newMessage.replace("has type", t("importLinks.hasType"));
  newMessage = newMessage.replace(
    "Only personal types are allowed to hold personalized advisor configuration",
    t("importLinks.personalTypes")
  );
  newMessage = newMessage.replace(
    "parent externalLinkID cannot be empty",
    t("importLinks.parrentCannotBeEmpty")
  );
  newMessage = newMessage.replace(
    "advisor email is mandatory",
    t("importLinks.advisorEmailMandatory")
  );
  newMessage = newMessage.replace(
    "login cannot be empty",
    t("importLinks.loginCannotBeEmpty")
  );
  newMessage = newMessage.replace(
    "error with password",
    t("importLinks.errorWithPassword")
  );
  newMessage = newMessage.replace(
    "couldn't create/update config",
    t("importLinks.couldntCreateConfig")
  );
  return newMessage;
};

const formatApiError = (error: any) => {
  const validationErrors = (error?.data?.data ??
    error?.data) as ValidationError[];

  const validationErrorsFmt = validationErrors
    .map((ve) => {
      if (ve.code?.length !== 0) {
        return t(ve.code || "");
      }

      if (ve.field === "customers") {
        return "app.signValidation.customer";
      }
      if (ve.field === "identification") {
        return "app.signValidation.field";
      }

      return ve.message;
    })
    .join("\n");

  let errFmt = "";
  if (validationErrorsFmt.length !== 0) {
    errFmt = validationErrorsFmt;
  } else {
    errFmt = error?.data?.message;
  }

  return errFmt;
};

const formatters = {
  formatPrice,
  formatPriceWithoutCurrency,
  formatDate,
  formatTime,
  formatTableTime,
  formatOpenHours,
  formatAddress,
  formatPin,
  formatEmail,
  openHoursToValue,
  inputDate,
  apiDate,
  formatTimeWithoutSeconds,
  formatExternalLinkImportResult,
  formatApiError,
};

function useFormatters(): typeof formatters {
  return formatters;
}

export default useFormatters;
