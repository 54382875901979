import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdvisors: build.query<GetAdvisorsApiResponse, GetAdvisorsApiArg>({
      query: (queryArg) => ({
        url: `/v1/advisors/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          hasValidNBSLicence: queryArg.hasValidNbsLicence,
          keyword: queryArg.keyword,
        },
      }),
    }),
    getAdvisorsListAll: build.query<
      GetAdvisorsListAllApiResponse,
      GetAdvisorsListAllApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/advisors/list-all`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          nbs: queryArg.nbs,
          advisorStates: queryArg.advisorStates,
          capitalMarket: queryArg.capitalMarket,
        },
      }),
    }),
    getAdvisorPositions: build.query<
      GetAdvisorPositionsApiResponse,
      GetAdvisorPositionsApiArg
    >({
      query: () => ({ url: `/v1/advisors/positions` }),
    }),
    getAdvisorSectorTypes: build.query<
      GetAdvisorSectorTypesApiResponse,
      GetAdvisorSectorTypesApiArg
    >({
      query: () => ({ url: `/v1/advisors/sector-types` }),
    }),
    getAdvisorById: build.query<
      GetAdvisorByIdApiResponse,
      GetAdvisorByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/advisors/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    addAdvisor: build.mutation<AddAdvisorApiResponse, AddAdvisorApiArg>({
      query: (queryArg) => ({
        url: `/v1/customers/${queryArg.id}/advisors`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    removeAdvisor: build.mutation<
      RemoveAdvisorApiResponse,
      RemoveAdvisorApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/customers/${queryArg.id}/advisors/${queryArg.advisorId}`,
        method: "DELETE",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getMyAdvisor: build.query<GetMyAdvisorApiResponse, GetMyAdvisorApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/advisor/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    seedAdvisors: build.mutation<SeedAdvisorsApiResponse, SeedAdvisorsApiArg>({
      query: (queryArg) => ({
        url: `/v1/seeder/advisors/import-local`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeSingleAdvisor: build.mutation<
      SynchronizeSingleAdvisorApiResponse,
      SynchronizeSingleAdvisorApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/advisors/${queryArg.advisorId}/sync`,
        method: "PATCH",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    synchronizeAdvisors: build.mutation<
      SynchronizeAdvisorsApiResponse,
      SynchronizeAdvisorsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/synchronization/advisors/${queryArg.days}`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type GetAdvisorsApiResponse = /** status 200 OK */ AdvisorPage;
export type GetAdvisorsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,firstname:asc. Supported keys: created_at, firstname, lastname, email. */
  sort?: string[];
  /** true */
  hasValidNbsLicence?: boolean;
  /** keyword to search for in users */
  keyword?: string;
};
export type GetAdvisorsListAllApiResponse =
  /** status 200 OK */ AdvisorListAllPage;
export type GetAdvisorsListAllApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,firstname:asc. Supported keys: created_at, displayName, evidenceNumber, email. */
  sort?: string[];
  /** keyword to search for in users */
  keyword?: string;
  /** apply active mentor filter */
  nbs?: boolean;
  /** allowed states */
  advisorStates?: string[];
  /** use capitalMarket condition */
  capitalMarket?: boolean;
};
export type GetAdvisorPositionsApiResponse =
  /** status 200 OK */ AdvisorPosition[];
export type GetAdvisorPositionsApiArg = void;
export type GetAdvisorSectorTypesApiResponse =
  /** status 200 OK */ AdvisorSectorType[];
export type GetAdvisorSectorTypesApiArg = void;
export type GetAdvisorByIdApiResponse = /** status 200 OK */ Advisor;
export type GetAdvisorByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type AddAdvisorApiResponse = /** status 200 ok */ SuccessResponse200;
export type AddAdvisorApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type RemoveAdvisorApiResponse = /** status 200 ok */ SuccessResponse200;
export type RemoveAdvisorApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** uuid */
  advisorId: string;
};
export type GetMyAdvisorApiResponse = /** status 200 OK */ Advisor;
export type GetMyAdvisorApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SeedAdvisorsApiResponse = /** status 200 OK */ SuccessResponse200;
export type SeedAdvisorsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
};
export type SynchronizeSingleAdvisorApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeSingleAdvisorApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  advisorId: string;
};
export type SynchronizeAdvisorsApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type SynchronizeAdvisorsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** int */
  days: number;
};
export type AdvisorFilter = {
  hasValidNBSLicence?: boolean;
  keyword?: string;
};
export type CountryKey = "sk";
export type Country = {
  key?: CountryKey;
  name?: string;
};
export type Address = {
  city?: string;
  country?: Country;
  street?: string;
  zip?: string;
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type FavouriteExternalLink = {
  createdAt?: string;
  externalLink?: ExternalLink;
  order?: number;
  updatedAt?: string;
};
export type GenderKey = "gk-male" | "gk-female" | "gk-none";
export type Gender = {
  key?: GenderKey;
  name?: string;
};
export type AdvisorPositionKey =
  | "SP"
  | "KR"
  | "RR"
  | "OR"
  | "M"
  | "S3"
  | "S2"
  | "S1"
  | "SK"
  | "K2"
  | "K1"
  | "T"
  | "BP"
  | "BP90"
  | "BP85"
  | "BP70"
  | "BP00"
  | "FEL1"
  | "FEL2"
  | "FEL3"
  | "FEL4";
export type AdvisorPosition = {
  higherMLM?: AdvisorPositionKey;
  key?: AdvisorPositionKey;
  name?: string;
};
export type AdvisorSector = {
  certificate_from?: string;
  certificate_to?: string;
  createdAt?: string;
  id?: string;
  ofv_from?: string;
  ofv_to?: string;
  registration_from?: string;
  registration_to?: string;
  type?: AdvisorSectorType;
  updatedAt?: string;
};
export type AdvisorTypeKey = "at-fo" | "at-fop";
export type AdvisorType = {
  key?: AdvisorTypeKey;
  name?: string;
};
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type Advisor = {
  businessAddress?: Address;
  businessName?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  externalId?: string;
  favouriteLinks?: FavouriteExternalLink[];
  firstname?: string;
  gender?: Gender;
  ico?: string;
  id?: string;
  lastname?: string;
  mentor?: Advisor;
  nbsRegistrationNumber?: string;
  parent?: Advisor;
  permanentAddress?: Address;
  phone_number?: string;
  pin?: string;
  position?: AdvisorPosition;
  sectors?: AdvisorSector[];
  title?: string;
  type?: AdvisorType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  user?: User;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type AdvisorPage = {
  count?: number;
  filter?: AdvisorFilter;
  items?: Advisor[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type RoleUserStatusKey = "rus-active" | "rus-deactivated";
export type AdvisorListAllFilter = {
  advisorStates?: RoleUserStatusKey[];
  capitalMarket?: boolean;
  keyword?: string;
  nbs?: boolean;
};
export type AdvisorProtectedView = {
  displayName?: string;
  email?: string;
  evidenceNumber?: string;
  firstname?: string;
  id?: string;
  lastname?: string;
  phone_number?: string;
};
export type AdvisorListAllPage = {
  count?: number;
  filter?: AdvisorListAllFilter;
  items?: AdvisorProtectedView[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export const {
  useGetAdvisorsQuery,
  useGetAdvisorsListAllQuery,
  useGetAdvisorPositionsQuery,
  useGetAdvisorSectorTypesQuery,
  useGetAdvisorByIdQuery,
  useAddAdvisorMutation,
  useRemoveAdvisorMutation,
  useGetMyAdvisorQuery,
  useSeedAdvisorsMutation,
  useSynchronizeSingleAdvisorMutation,
  useSynchronizeAdvisorsMutation,
} = injectedRtkApi;
