import React, { ChangeEvent } from "react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PermissionRuleStatusSelectOptions from "../../enums/PermissionRuleStatusSelectOptions";

interface Properties {
  selectedOption?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

function PermissionRuleStatusSelect({ selectedOption, onChange }: Properties) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex text-gray-500 text-sm mb-1">
        <span>{t("app.form.status")}</span>
      </div>
      <select
        className="block text-gray-700 mb-2 border border-gray-300 w-full p-2 rounded"
        defaultValue={selectedOption}
        onChange={onChange}
      >
        {PermissionRuleStatusSelectOptions.map((item) => (
          <option
            key={`index-${item.key}`}
            label={t(item.name) || ""}
            value={item.key}
          >
            {t(item.name)}
          </option>
        ))}
      </select>
    </div>
  );
}

PermissionRuleStatusSelect.propTypes = {
  selectedOption: propTypes.string,
};

PermissionRuleStatusSelect.defaultProps = {
  selectedOption: undefined,
  onChange: undefined,
};

export default PermissionRuleStatusSelect;
