import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query<GetEventsApiResponse, GetEventsApiArg>({
      query: (queryArg) => ({
        url: `/v1/events/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          documentId: queryArg.documentId,
          eventStates: queryArg.eventStates,
        },
      }),
    }),
    updateEvent: build.mutation<UpdateEventApiResponse, UpdateEventApiArg>({
      query: (queryArg) => ({
        url: `/v1/events/`,
        method: "POST",
        body: queryArg.eventUpdateRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getEventById: build.query<GetEventByIdApiResponse, GetEventByIdApiArg>({
      query: (queryArg) => ({
        url: `/v1/events/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type GetEventsApiResponse = /** status 200 OK */ EventPage;
export type GetEventsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,display_name:asc. Supported keys: created_at, display_name. */
  sort?: string[];
  /** keyword to search for in events */
  keyword?: string;
  /** uuid */
  documentId?: string;
  /** allowed states */
  eventStates?: string[];
};
export type UpdateEventApiResponse = /** status 200 OK */ SuccessResponse200;
export type UpdateEventApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** Event to be updated */
  eventUpdateRequest: EventUpdateRequest;
};
export type GetEventByIdApiResponse = /** status 200 OK */ Event;
export type GetEventByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type EventTypeKey =
  | "et-user-login"
  | "et-user-logout"
  | "et-user-initiated-signature"
  | "et-document-signed"
  | "et-document-state-changed"
  | "et-document-created"
  | "et-document-updated"
  | "et-external-link-created"
  | "et-external-link-updated"
  | "et-external-link-state-changed"
  | "et-external-link-config-created"
  | "et-external-link-config-updated"
  | "et-external-link-config-deleted"
  | "et-external-link-config-state-changed"
  | "et-role-user-state-changed"
  | "et-user-state-changed"
  | "et-file-delete-error"
  | "et-permission-rule-created"
  | "et-permission-rule-updated"
  | "et-permission-rule-deleted"
  | "et-permission-rule-rights-created"
  | "et-permission-rule-rights-updated"
  | "et-permission-rule-rights-deleted"
  | "et-sync-error"
  | "et-sync-advisor"
  | "et-icm-brokers-response";
export type EventFilter = {
  documentId?: string;
  eventTypes?: EventTypeKey[];
  keyword?: string;
};
export type CountryKey = "sk";
export type Country = {
  key?: CountryKey;
  name?: string;
};
export type Address = {
  city?: string;
  country?: Country;
  street?: string;
  zip?: string;
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type FavouriteExternalLink = {
  createdAt?: string;
  externalLink?: ExternalLink;
  order?: number;
  updatedAt?: string;
};
export type GenderKey = "gk-male" | "gk-female" | "gk-none";
export type Gender = {
  key?: GenderKey;
  name?: string;
};
export type AdvisorPositionKey =
  | "SP"
  | "KR"
  | "RR"
  | "OR"
  | "M"
  | "S3"
  | "S2"
  | "S1"
  | "SK"
  | "K2"
  | "K1"
  | "T"
  | "BP"
  | "BP90"
  | "BP85"
  | "BP70"
  | "BP00"
  | "FEL1"
  | "FEL2"
  | "FEL3"
  | "FEL4";
export type AdvisorPosition = {
  higherMLM?: AdvisorPositionKey;
  key?: AdvisorPositionKey;
  name?: string;
};
export type AdvisorSector = {
  certificate_from?: string;
  certificate_to?: string;
  createdAt?: string;
  id?: string;
  ofv_from?: string;
  ofv_to?: string;
  registration_from?: string;
  registration_to?: string;
  type?: AdvisorSectorType;
  updatedAt?: string;
};
export type AdvisorTypeKey = "at-fo" | "at-fop";
export type AdvisorType = {
  key?: AdvisorTypeKey;
  name?: string;
};
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type Advisor = {
  businessAddress?: Address;
  businessName?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  externalId?: string;
  favouriteLinks?: FavouriteExternalLink[];
  firstname?: string;
  gender?: Gender;
  ico?: string;
  id?: string;
  lastname?: string;
  mentor?: Advisor;
  nbsRegistrationNumber?: string;
  parent?: Advisor;
  permanentAddress?: Address;
  phone_number?: string;
  pin?: string;
  position?: AdvisorPosition;
  sectors?: AdvisorSector[];
  title?: string;
  type?: AdvisorType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  user?: User;
};
export type FileStatusKey = "fs-uploaded" | "fs-active" | "fs-deleted";
export type FileStatus = {
  key?: FileStatusKey;
  name?: string;
};
export type File = {
  createdAt?: string;
  extension?: string;
  id?: string;
  key?: string;
  name?: string;
  status?: FileStatus;
  updatedAt?: string;
};
export type AdvisorCustomerOriginKey = "aco-icm" | "aco-portal";
export type CustomerOrigin = {
  key?: AdvisorCustomerOriginKey;
  name?: string;
};
export type AvisorCustomer = {
  advisor?: Advisor;
  origin?: CustomerOrigin;
};
export type IcmPortalAddressResponse = {
  city?: string;
  street?: string;
  zip?: string;
};
export type IcmPortalCustomerResponse = {
  authorizedPersonPosition?: string;
  businessAddress?: IcmPortalAddressResponse;
  businessName?: string;
  cop?: string;
  correspondenceAddress?: IcmPortalAddressResponse;
  dateOfBirth?: string;
  email?: string;
  firstname?: string;
  foreignClient?: boolean;
  genderKey?: GenderKey;
  ico?: string;
  lastname?: string;
  note?: string;
  otherIDCardNumber?: string;
  passportNumber?: string;
  permanentAddress?: IcmPortalAddressResponse;
  phoneNumber?: string;
  pin?: string;
  title?: string;
};
export type CustomerTypeKey = "ct-fo" | "ct-fop" | "ct-po";
export type CustomerType = {
  key?: CustomerTypeKey;
  name?: string;
};
export type Customer = {
  advisors?: AvisorCustomer[];
  authorizedPersonPosition?: string;
  businessAddress?: Address;
  businessName?: string;
  citizenship?: string;
  clientDisplayName?: string;
  contractAgreement?: boolean;
  contractSignedAt?: string;
  cop?: string;
  correspondenceAddress?: Address;
  createdAt?: string;
  dateOfBirth?: string;
  dateOfConsent?: string;
  email?: string;
  firstname?: string;
  foreignClient?: boolean;
  gender?: Gender;
  icmDiff?: IcmPortalCustomerResponse;
  icmID?: string;
  icmStatus?: boolean;
  ico?: string;
  id?: string;
  lastIDGuardIdentification?: string;
  lastname?: string;
  note?: string;
  opValidUntil?: string;
  origin?: OriginSystem;
  otherIDCardNumber?: string;
  otherIDCardType?: string;
  otherIDCardValidUntil?: string;
  pasValidUntil?: string;
  passportNumber?: string;
  permanentAddress?: Address;
  personalAdvisor?: Advisor;
  personalMarketingInformation?: boolean;
  phoneNumber?: string;
  pin?: string;
  profession?: string;
  title?: string;
  type?: CustomerType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  validIdentificationId?: string;
};
export type CustomerDocumentFile = {
  customer?: Customer;
  file?: File;
  id?: string;
  isCcaFile?: boolean;
  note?: string;
};
export type AdvisorProtectedView = {
  displayName?: string;
  email?: string;
  evidenceNumber?: string;
  firstname?: string;
  id?: string;
  lastname?: string;
  phone_number?: string;
};
export type DocumentSignatureNotification = {
  email?: string;
  link?: string;
};
export type SignatureTypeKey = "st-email" | "st-link";
export type SignatureType = {
  key?: SignatureTypeKey;
  name?: string;
};
export type DocumentStatusKey =
  | "ds-draft"
  | "ds-signed"
  | "ds-signing-in-progress"
  | "ds-signing-rejected"
  | "ds-signing-expired"
  | "ds-deleted"
  | "ds-check-draft"
  | "ds-check-in-office"
  | "ds-check-closed"
  | "ds-check-deleted"
  | "ds-check-incoming-post";
export type DocumentStatus = {
  key?: DocumentStatusKey;
  name?: string;
};
export type DocumentTypeKey = "dt-cca" | "dt-check";
export type DocumentType = {
  key?: DocumentTypeKey;
  name?: string;
};
export type Document = {
  advisor?: Advisor;
  content?: {
    [key: string]: any;
  };
  contractId?: string;
  controlPdf?: File;
  createdAt?: string;
  customerFiles?: CustomerDocumentFile[];
  customers?: Customer[];
  displayID?: string;
  displayName?: string;
  file?: File;
  id?: string;
  implementor?: Advisor;
  mentor?: Advisor;
  sharedAdvisor?: AdvisorProtectedView;
  signatureNotification?: DocumentSignatureNotification[];
  signatureType?: SignatureType;
  status?: DocumentStatus;
  type?: DocumentType;
  updatedAt?: string;
  url?: string;
};
export type EventType = {
  key?: EventTypeKey;
  name?: string;
};
export type Event = {
  advisor?: Advisor;
  createdAt?: string;
  data?: {
    [key: string]: any;
  };
  document?: Document;
  id?: string;
  type?: EventType;
  updatedAt?: string;
  user?: User;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type EventPage = {
  count?: number;
  filter?: EventFilter;
  items?: Event[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type EventUpdateRequest = {
  data?: string;
  typeKey: EventTypeKey;
  userId: string;
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export const {
  useGetEventsQuery,
  useUpdateEventMutation,
  useGetEventByIdQuery,
} = injectedRtkApi;
