import { useState } from "react";

import { useNavigate } from "react-router";

import {
  PermissionObjectTypeKey,
  PermissionRuleRightsActionTypeKey,
  RoleUserRight,
} from "../api/permissionRule.generated";
import PermissionOperationTypeKey from "../enums/PermissionOperationType";
import AllRoutes from "../features/route/Route";
import useLogger from "./logger";
import { SortablePaginationQuery, useQuery } from "./query";
import { makeRoute } from "./route";

export interface PermissionsQuery extends SortablePaginationQuery {
  keyword: string;
}

export type PermissionsQueryDispatcher = (query: PermissionsQuery) => void;

type PermissionsQueryHook = [
  PermissionsQuery,
  (query: PermissionsQuery) => void
];

const localStorageSizeKey = "permissions.page.size";

function getCustomersPageSizeFromStorage(): number {
  return Number(localStorage.getItem(localStorageSizeKey)) || 10;
}

function setCustomersPageSizeToStorage(newSize: number): void {
  localStorage.setItem(localStorageSizeKey, newSize.toString());
}

export function usePermissionListQuery(): PermissionsQueryHook {
  const navigate = useNavigate();
  const query = useQuery();
  const { logger } = useLogger();
  const [permissionQuery, setPermissionsQuery] = useState<PermissionsQuery>({
    page: query.page ?? 1,
    size: query.size ?? getCustomersPageSizeFromStorage(),
    sort: query.sort ? [query.sort] : ["createdAt:desc"],
    keyword: query.keyword ?? "",
  });

  logger.debug("PARSED DOCUMENT QUERY", permissionQuery);

  return [
    permissionQuery,
    (updatedPermissionsQuery: PermissionsQuery) => {
      const queryString = Object.keys(updatedPermissionsQuery)
        .map((key) => {
          const parameter =
            updatedPermissionsQuery[key as keyof PermissionsQuery];
          if (Array.isArray(parameter)) {
            const val = parameter.join(",");
            return val.length > 0 ? `${key}=${val}` : undefined;
          }

          return (parameter ?? "").toString().length > 0
            ? `${key}=${parameter}`
            : undefined;
        })
        .filter((item) => item !== undefined)
        .join("&");

      logger.debug("QUERY STRING", queryString);

      setCustomersPageSizeToStorage(updatedPermissionsQuery.size ?? 10);
      navigate(`${makeRoute(AllRoutes.RULES_LIST)}?${queryString}`);
      setPermissionsQuery(updatedPermissionsQuery);
    },
  ];
}

export function useGetComputedCrudProperty(): {
  getComputedCrudProperty: (
    computedCrud: RoleUserRight[] | undefined,
    key: PermissionObjectTypeKey,
    property: PermissionOperationTypeKey
  ) => PermissionRuleRightsActionTypeKey | undefined;
} {
  return {
    getComputedCrudProperty: (
      computedCrud: RoleUserRight[] | undefined,
      key: PermissionObjectTypeKey,
      property: PermissionOperationTypeKey
    ): PermissionRuleRightsActionTypeKey | undefined => {
      if (!computedCrud) return undefined;

      const item = computedCrud.find(
        (operation: RoleUserRight) => operation.rightType === key
      );

      if (!item) return undefined;

      return item[property] as PermissionRuleRightsActionTypeKey;
    },
  };
}

const allHooks = {
  usePermissionListQuery,
  useGetComputedCrudProperty,
};

export default allHooks;
