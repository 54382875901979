import PermissionOperationTypeKey from "../enums/PermissionOperationType";
import PermissionRuleActionTypeKey from "../enums/PermissionRuleActionTypeKey";
import PermissionType from "../enums/PermissionType";
import { authSelector } from "../features/auth/authSlice";
import { useGetComputedCrudProperty } from "./permissions";
import { useAppSelector } from "./store";

export function useZUZRights(action: PermissionOperationTypeKey): boolean {
  const { currentRole } = useAppSelector(authSelector);
  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const zuzUpdatePermissions = getComputedCrudProperty(
    currentRole.computedRights,
    PermissionType.ZUZ,
    action
  );

  return zuzUpdatePermissions === PermissionRuleActionTypeKey.ADD;
}

export function useCheckRights(action: PermissionOperationTypeKey): boolean {
  const { currentRole } = useAppSelector(authSelector);
  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const checkUpdatePermissions = getComputedCrudProperty(
    currentRole.computedRights,
    PermissionType.CHECK,
    action
  );

  return checkUpdatePermissions === PermissionRuleActionTypeKey.ADD;
}

export function useCustomersRights(
  action: PermissionOperationTypeKey
): boolean {
  const { currentRole } = useAppSelector(authSelector);
  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const checkUpdatePermissions = getComputedCrudProperty(
    currentRole.computedRights,
    PermissionType.CUSTOMERS,
    action
  );

  return checkUpdatePermissions === PermissionRuleActionTypeKey.ADD;
}

export function useExternalLinksRights(
  action: PermissionOperationTypeKey
): boolean {
  const { currentRole } = useAppSelector(authSelector);
  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const checkUpdatePermissions = getComputedCrudProperty(
    currentRole.computedRights,
    PermissionType.EXTERNAL_LINKS,
    action
  );

  return checkUpdatePermissions === PermissionRuleActionTypeKey.ADD;
}

export function useIdentificationsRights(
  action: PermissionOperationTypeKey
): boolean {
  const { currentRole } = useAppSelector(authSelector);
  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const checkUpdatePermissions = getComputedCrudProperty(
    currentRole.computedRights,
    PermissionType.IDENTIFICATIONS,
    action
  );

  return checkUpdatePermissions === PermissionRuleActionTypeKey.ADD;
}

export function usePermissionsRights(
  action: PermissionOperationTypeKey
): boolean {
  const { currentRole } = useAppSelector(authSelector);
  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const checkUpdatePermissions = getComputedCrudProperty(
    currentRole.computedRights,
    PermissionType.PERMISSIONS,
    action
  );

  return checkUpdatePermissions === PermissionRuleActionTypeKey.ADD;
}

export function useSystemSettingsRights(
  action: PermissionOperationTypeKey
): boolean {
  const { currentRole } = useAppSelector(authSelector);
  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const checkUpdatePermissions = getComputedCrudProperty(
    currentRole.computedRights,
    PermissionType.SYSTEM_SETTINGS,
    action
  );

  return checkUpdatePermissions === PermissionRuleActionTypeKey.ADD;
}

export function useUsersRights(action: PermissionOperationTypeKey): boolean {
  const { currentRole } = useAppSelector(authSelector);
  const { getComputedCrudProperty } = useGetComputedCrudProperty();

  const checkUpdatePermissions = getComputedCrudProperty(
    currentRole.computedRights,
    PermissionType.USERS,
    action
  );

  return checkUpdatePermissions === PermissionRuleActionTypeKey.ADD;
}

const allHooks = {
  useCheckRights,
  useZUZRights,
  useCustomersRights,
  useExternalLinksRights,
  useIdentificationsRights,
  usePermissionsRights,
  useSystemSettingsRights,
  useUsersRights,
};

export default allHooks;
