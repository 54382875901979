import { baseSplitApi as api } from "./baseApi";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadAdminIdentificationDocument: build.mutation<
      UploadAdminIdentificationDocumentApiResponse,
      UploadAdminIdentificationDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/admin/identifications/${queryArg.id}/upload-document`,
        method: "POST",
        body: queryArg.body,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getIdentifications: build.query<
      GetIdentificationsApiResponse,
      GetIdentificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/identifications/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          identificationStates: queryArg.identificationStates,
          level: queryArg.level,
        },
      }),
    }),
    createIdentification: build.mutation<
      CreateIdentificationApiResponse,
      CreateIdentificationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/identifications/`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    identificationsWebhookCallback: build.mutation<
      IdentificationsWebhookCallbackApiResponse,
      IdentificationsWebhookCallbackApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/identifications/update-result`,
        method: "PATCH",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: { sessionId: queryArg.sessionId, result: queryArg.result },
      }),
    }),
    getIdentificationById: build.query<
      GetIdentificationByIdApiResponse,
      GetIdentificationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/identifications/${queryArg.id}`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    updateIdentification: build.mutation<
      UpdateIdentificationApiResponse,
      UpdateIdentificationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/identifications/${queryArg.id}`,
        method: "PUT",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    previewIdentification: build.mutation<
      PreviewIdentificationApiResponse,
      PreviewIdentificationApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/identifications/${queryArg.id}/preview`,
        method: "POST",
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: { download: queryArg.download },
      }),
    }),
    updateStatusIdentifications: build.mutation<
      UpdateStatusIdentificationsApiResponse,
      UpdateStatusIdentificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/identifications/${queryArg.id}/update-status`,
        method: "PATCH",
        body: queryArg.identificationUpdateStatusRequest,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
      }),
    }),
    getMyIdentifications: build.query<
      GetMyIdentificationsApiResponse,
      GetMyIdentificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/me/identifications/`,
        headers: {
          "Accept-Language": queryArg["Accept-Language"],
          "X-Role-Id": queryArg["X-Role-Id"],
        },
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort: queryArg.sort,
          keyword: queryArg.keyword,
          identificationStates: queryArg.identificationStates,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as spfApi };
export type UploadAdminIdentificationDocumentApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UploadAdminIdentificationDocumentApiArg = {
  /** uuid */
  id: string;
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  body: {
    file: Blob;
  };
};
export type GetIdentificationsApiResponse =
  /** status 200 OK */ IdentificationPage;
export type GetIdentificationsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,display_name:asc. Supported keys: created_at, display_name. */
  sort?: string[];
  /** keyword to search for in identifications */
  keyword?: string;
  /** allowed states */
  identificationStates?: string[];
  /** visibility level */
  level?: string;
};
export type CreateIdentificationApiResponse =
  /** status 200 OK */ Identification;
export type CreateIdentificationApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type IdentificationsWebhookCallbackApiResponse =
  /** status 200 Status OK */ SuccessResponse200;
export type IdentificationsWebhookCallbackApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** string */
  sessionId: string;
  /** string */
  result: string;
};
export type GetIdentificationByIdApiResponse =
  /** status 200 OK */ Identification;
export type GetIdentificationByIdApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type UpdateIdentificationApiResponse =
  /** status 200 OK */ Identification;
export type UpdateIdentificationApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
};
export type PreviewIdentificationApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type PreviewIdentificationApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** Enables you to download the preview */
  download: boolean;
};
export type UpdateStatusIdentificationsApiResponse =
  /** status 200 OK */ SuccessResponse200;
export type UpdateStatusIdentificationsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** uuid */
  id: string;
  /** the new status */
  identificationUpdateStatusRequest: IdentificationUpdateStatusRequest;
};
export type GetMyIdentificationsApiResponse =
  /** status 200 OK */ IdentificationPage;
export type GetMyIdentificationsApiArg = {
  /** string */
  "Accept-Language"?: string;
  /** uuid */
  "X-Role-Id": string;
  /** page */
  page?: number;
  /** size */
  size?: number;
  /** Format is sort=email:desc,display_name:asc. Supported keys: created_at, display_name. */
  sort?: string[];
  /** keyword to search for in identifications */
  keyword?: string;
  /** allowed states */
  identificationStates?: string[];
};
export type SuccessResponse200 = {
  id?: string;
  status?: string;
};
export type ValidationError = {
  code?: string;
  field?: string;
  message?: string;
};
export type ErrorResponse400 = {
  code?: number;
  data?: ValidationError[];
  message?: string;
};
export type ErrorResponse401 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type ErrorResponse500 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type FilterOptionsKey = "fo-user-owned" | "fo-structural" | "fo-all";
export type IdentificationStatusKey =
  | "is-initiated"
  | "is-success"
  | "is-refused"
  | "is-error"
  | "is-success-irrelevant"
  | "is-declined";
export type IdentificationFilter = {
  advisorId?: string;
  customerId?: string;
  keyword?: string;
  level?: FilterOptionsKey;
  states?: IdentificationStatusKey[];
};
export type CountryKey = "sk";
export type Country = {
  key?: CountryKey;
  name?: string;
};
export type Address = {
  city?: string;
  country?: Country;
  street?: string;
  zip?: string;
};
export type ExternalLinkAuthTypeKey = "elat-sso" | "elat-log" | "elat-noa";
export type LinkType = {
  key?: ExternalLinkAuthTypeKey;
  name?: string;
};
export type ExternalLinkCategoryKey =
  | "lct-int"
  | "lct-ins"
  | "lct-bnk"
  | "lct-dss"
  | "lct-oth"
  | "lct-inc";
export type ExternalLinkCategory = {
  key?: ExternalLinkCategoryKey;
  name?: string;
};
export type ImageResponse = {
  type?: string;
  url?: string;
};
export type ExternalLinkConfigurationStatusKey =
  | "elcs-active"
  | "elcs-deactivated";
export type ExternalLinkConfigurationStatus = {
  key?: ExternalLinkConfigurationStatusKey;
  name?: string;
};
export type ExternalLinkConfiguration = {
  advisorExternalID?: string;
  advisorFirstame?: string;
  advisorId?: string;
  advisorLastname?: string;
  createdAt?: string;
  id?: string;
  login?: string;
  password?: string;
  status?: ExternalLinkConfigurationStatus;
  updatedAt?: string;
};
export type AdvisorSectorTypeKey =
  | "ast-aml"
  | "ast-ins"
  | "ast-len"
  | "ast-acc"
  | "ast-cap"
  | "ast-sup"
  | "ast-old";
export type AdvisorSectorType = {
  key?: AdvisorSectorTypeKey;
  name?: string;
};
export type ExternalLinkSector = {
  mandatory?: boolean;
  sectorType?: AdvisorSectorType;
};
export type ExternalLinkStatusKey = "els-active" | "els-deactivated";
export type ExternalLinkStatus = {
  key?: ExternalLinkStatusKey;
  name?: string;
};
export type ExternalLinkTypeKey = "ast-per" | "ast-gen";
export type ExternalLinkType = {
  key?: ExternalLinkTypeKey;
  name?: string;
};
export type ExternalLink = {
  authType?: LinkType;
  category?: ExternalLinkCategory;
  createdAt?: string;
  displayLink?: string;
  id?: string;
  image?: ImageResponse[];
  imageId?: string;
  linkConfigurations?: ExternalLinkConfiguration[];
  name?: string;
  order?: number;
  sectors?: ExternalLinkSector[];
  status?: ExternalLinkStatus;
  type?: ExternalLinkType;
  updatedAt?: string;
};
export type FavouriteExternalLink = {
  createdAt?: string;
  externalLink?: ExternalLink;
  order?: number;
  updatedAt?: string;
};
export type GenderKey = "gk-male" | "gk-female" | "gk-none";
export type Gender = {
  key?: GenderKey;
  name?: string;
};
export type AdvisorPositionKey =
  | "SP"
  | "KR"
  | "RR"
  | "OR"
  | "M"
  | "S3"
  | "S2"
  | "S1"
  | "SK"
  | "K2"
  | "K1"
  | "T"
  | "BP"
  | "BP90"
  | "BP85"
  | "BP70"
  | "BP00"
  | "FEL1"
  | "FEL2"
  | "FEL3"
  | "FEL4";
export type AdvisorPosition = {
  higherMLM?: AdvisorPositionKey;
  key?: AdvisorPositionKey;
  name?: string;
};
export type AdvisorSector = {
  certificate_from?: string;
  certificate_to?: string;
  createdAt?: string;
  id?: string;
  ofv_from?: string;
  ofv_to?: string;
  registration_from?: string;
  registration_to?: string;
  type?: AdvisorSectorType;
  updatedAt?: string;
};
export type AdvisorTypeKey = "at-fo" | "at-fop";
export type AdvisorType = {
  key?: AdvisorTypeKey;
  name?: string;
};
export type OriginSystemKey = "uo-portal" | "uo-icm";
export type OriginSystem = {
  key?: OriginSystemKey;
  name?: string;
};
export type UserStatusKey = "us-active" | "us-deactivated" | "us-resigned";
export type UserStatus = {
  key?: UserStatusKey;
  name?: string;
};
export type User = {
  createdAt?: string;
  displayName: string;
  email: string;
  id?: string;
  originSystem?: OriginSystem;
  status?: UserStatus;
  updatedAt?: string;
};
export type Advisor = {
  businessAddress?: Address;
  businessName?: string;
  createdAt?: string;
  displayName?: string;
  evidenceNumber: string;
  externalId?: string;
  favouriteLinks?: FavouriteExternalLink[];
  firstname?: string;
  gender?: Gender;
  ico?: string;
  id?: string;
  lastname?: string;
  mentor?: Advisor;
  nbsRegistrationNumber?: string;
  parent?: Advisor;
  permanentAddress?: Address;
  phone_number?: string;
  pin?: string;
  position?: AdvisorPosition;
  sectors?: AdvisorSector[];
  title?: string;
  type?: AdvisorType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  user?: User;
};
export type AdvisorCustomerOriginKey = "aco-icm" | "aco-portal";
export type CustomerOrigin = {
  key?: AdvisorCustomerOriginKey;
  name?: string;
};
export type AvisorCustomer = {
  advisor?: Advisor;
  origin?: CustomerOrigin;
};
export type IcmPortalAddressResponse = {
  city?: string;
  street?: string;
  zip?: string;
};
export type IcmPortalCustomerResponse = {
  authorizedPersonPosition?: string;
  businessAddress?: IcmPortalAddressResponse;
  businessName?: string;
  cop?: string;
  correspondenceAddress?: IcmPortalAddressResponse;
  dateOfBirth?: string;
  email?: string;
  firstname?: string;
  foreignClient?: boolean;
  genderKey?: GenderKey;
  ico?: string;
  lastname?: string;
  note?: string;
  otherIDCardNumber?: string;
  passportNumber?: string;
  permanentAddress?: IcmPortalAddressResponse;
  phoneNumber?: string;
  pin?: string;
  title?: string;
};
export type CustomerTypeKey = "ct-fo" | "ct-fop" | "ct-po";
export type CustomerType = {
  key?: CustomerTypeKey;
  name?: string;
};
export type Customer = {
  advisors?: AvisorCustomer[];
  authorizedPersonPosition?: string;
  businessAddress?: Address;
  businessName?: string;
  citizenship?: string;
  clientDisplayName?: string;
  contractAgreement?: boolean;
  contractSignedAt?: string;
  cop?: string;
  correspondenceAddress?: Address;
  createdAt?: string;
  dateOfBirth?: string;
  dateOfConsent?: string;
  email?: string;
  firstname?: string;
  foreignClient?: boolean;
  gender?: Gender;
  icmDiff?: IcmPortalCustomerResponse;
  icmID?: string;
  icmStatus?: boolean;
  ico?: string;
  id?: string;
  lastIDGuardIdentification?: string;
  lastname?: string;
  note?: string;
  opValidUntil?: string;
  origin?: OriginSystem;
  otherIDCardNumber?: string;
  otherIDCardType?: string;
  otherIDCardValidUntil?: string;
  pasValidUntil?: string;
  passportNumber?: string;
  permanentAddress?: Address;
  personalAdvisor?: Advisor;
  personalMarketingInformation?: boolean;
  phoneNumber?: string;
  pin?: string;
  profession?: string;
  title?: string;
  type?: CustomerType;
  updatedAt?: string;
  updatedBy?: User;
  updatedByICM?: string;
  validIdentificationId?: string;
};
export type CustomerIdentificationResponse = {
  COP?: string;
  displayName?: string;
  email?: string;
  ico?: string;
  id?: string;
  otherIDCardNumber?: string;
  otherIDCardType?: string;
  passportNumber?: string;
  pin?: string;
  type?: CustomerTypeKey;
};
export type FileStatusKey = "fs-uploaded" | "fs-active" | "fs-deleted";
export type FileStatus = {
  key?: FileStatusKey;
  name?: string;
};
export type File = {
  createdAt?: string;
  extension?: string;
  id?: string;
  key?: string;
  name?: string;
  status?: FileStatus;
  updatedAt?: string;
};
export type IdentificationProviderKey = "ip-idguard" | "ip-veriface";
export type IdentificationProvider = {
  key?: IdentificationProviderKey;
  name?: string;
};
export type IdentificationStatus = {
  key?: IdentificationStatusKey;
  name?: string;
};
export type Identification = {
  advisor?: Advisor;
  createdAt?: string;
  customer?: Customer;
  customerIdentificationEmail?: string;
  customers?: CustomerIdentificationResponse[];
  displayName?: string;
  id?: string;
  idCardNumber?: string;
  pdfFile?: File;
  pin?: string;
  provider?: IdentificationProvider;
  response?: string;
  status?: IdentificationStatus;
  updatedAt?: string;
  user?: User;
};
export type SortFieldDirection = "asc" | "desc";
export type SortFieldKey =
  | "createdAt"
  | "updatedAt"
  | "startsAt"
  | "validTo"
  | "validUntil"
  | "firstname"
  | "lastname"
  | "name"
  | "displayName"
  | "evidenceNumber"
  | "email"
  | "pin"
  | "ico"
  | "advisorEmail"
  | "customerEmail"
  | "mentorEmail"
  | "phoneNumber"
  | "displayId"
  | "street"
  | "city"
  | "zip"
  | "id"
  | "owner"
  | "type"
  | "status"
  | "customer"
  | "advisor"
  | "updatedBy";
export type SortField = {
  direction?: SortFieldDirection;
  field?: SortFieldKey;
};
export type IdentificationPage = {
  count?: number;
  filter?: IdentificationFilter;
  items?: Identification[];
  last?: boolean;
  page?: number;
  size?: number;
  sort?: SortField[];
};
export type ErrorResponse404 = {
  code?: number;
  data?: {
    [key: string]: string;
  }[];
  message?: string;
};
export type IdentificationUpdateStatusRequest = {
  statusKey?: IdentificationStatusKey;
};
export const {
  useUploadAdminIdentificationDocumentMutation,
  useGetIdentificationsQuery,
  useCreateIdentificationMutation,
  useIdentificationsWebhookCallbackMutation,
  useGetIdentificationByIdQuery,
  useUpdateIdentificationMutation,
  usePreviewIdentificationMutation,
  useUpdateStatusIdentificationsMutation,
  useGetMyIdentificationsQuery,
} = injectedRtkApi;
